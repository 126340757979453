angular.module('uprise')

    .controller('UserInvestorTypeController', function ($scope, UserService, $state) {

        $scope.investorType = "";
        $scope.selectedUserType = "";

        $scope.navigateToStart = function () {
          $state.transitionTo("welcome");
        };

        $scope.setUserInvestorType = function () {

            UserService.setUserType($scope.selectedUserType).then(() => {
                $state.transitionTo("welcome");

            }, (err) => {
                console.log(err);
            });

        };

    });