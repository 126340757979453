angular.module('uprise')

    .factory('ExchangeService', ['$http', '$q', function ($http, $q) {

        // This uses money.js to handle the actual conversions
        var exchangeService = {
            // we default to ZAR
            "country_code": "ZA",
            "country_name": "South Africa",
            "currency_symbol": "R",
            "currency_code": "ZAR",
            "currency_name": "Rand",
            "base": "ZAR",
            "rates": {
                "ZAR": 1.0
            },

            "refresh_exchange_info": function () {
                var instance = this;

                fx.base = instance.base;
                fx.rates = instance.rates;
                fx.settings['to'] = instance.currency_code;

                return $http.get('/api/pitch_funding/fetch_exchange_rate/', {'headers': {'Content-Type': 'application/json'}})
                    .then(res => {
                        instance.country_code = res.data.country_code;
                        instance.country_name = res.data.country_name;
                        instance.currency_symbol = res.data.currency_symbol;
                        instance.currency_code = res.data.currency_code;
                        instance.currency_name = res.data.currency_name;
                        instance.base = res.data.exchange_rates.base;
                        instance.rates = res.data.exchange_rates.rates;

                        fx.base = instance.base;
                        fx.rates = instance.rates;
                        fx.settings['to'] = instance.currency_code;

                    }).catch(function (data) {
                        console.log(data);
                    })
            },

            "exchange": function (amount) {
                var result = NaN;
                try {
                    result = fx.convert(amount, {from: 'ZAR', to: 'USD'});
                }
                catch (err) {
                }

                return result;
            },

            "exchange_promise": function (amount) {
                var deferred = $q.defer();
                var instance = this;

                var exchanged = instance.exchange(amount);

                if (isNaN(exchanged)) {
                    deferred.reject("Could not exchange values");
                }
                else {
                    deferred.resolve(exchanged);
                }

                return deferred.promise;
            }
        };

        exchangeService.refresh_exchange_info();

        return exchangeService;
    }]);
