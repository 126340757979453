angular.module('uprise')

    .factory('ContactService', function ($http) {

        let service = {

            submitContactForm: function (formData) {
                let req = {
                    method: 'POST',
                    url: '/api/user/contact_form',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                      'name': formData.name,
                      'email_address': formData.email_address,
                      'subject': formData.subject,
                      'message': formData.message,
                    },
                    timeout: 60000,
                };

                return $http(req);
            },

        };

        return service;

    });
