angular.module('uprise')
    .controller('PitchDetailsController', function ($scope, $state, $sce, $timeout, $anchorScroll, UserService, PitchSetupService, PitchService) {

            let pitchId = $state.params.pitch_id;
            let userAuthed = false;

            $scope.loading_page = true;

            $scope.pitchDetails = {};

            $scope.trustSrc = function (url) {
                return $sce.trustAsResourceUrl(url);
            };

            $scope.trustHtml = function (html) {
                return $sce.trustAsHtml(html);
            };

            $scope.$on('handleLoginStateChangeBroadcast', function (event, args) {
                let userAuthed = true;
            });

            let init = function () {
                    $('.modal').modal();

                    if ($state.params.tmtData) {
                        console.log($state.params.tmtData);
                        localStorage.setItem('tmtData', $state.params.tmtData);
                        UserService.checkUserSignedIn().then(res => {
                            if (res) {
                                UserService.saveAdmarulaToken($state.params.tmtData).then(() => {
                                    localStorage.removeItem('tmtData');
                                })
                            }
                        });
                    }

                    PitchSetupService.fetchPitchDetails(pitchId).then(res => {
                            $scope.pitchDetails = res.data;

                            $scope.pitchDetails.story = $scope.trustHtml($scope.pitchDetails.story);
                            $scope.pitchDetails.short_blurb = $scope.trustHtml($scope.pitchDetails.short_blurb);
                            $scope.pitchDetails.funding_goal_equity = $scope.pitchDetails.funding_goal_equity * 100;

                            let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (2 || -1) + '})?');
                            $scope.pitchDetails.funding_goal_equity = $scope.pitchDetails.funding_goal_equity.toString().match(re)[0];

                            if ($scope.pitchDetails.video) {
                                let videoSplit = $scope.pitchDetails.video.split("/");

                                if (videoSplit.length > 0) {

                                    if ($scope.pitchDetails.video.indexOf("youtu") > -1) {

                                        let videoID = videoSplit[videoSplit.length - 1];

                                        videoID = videoID.replace("watch?v=", "");

                                        $scope.pitchDetails.video = 'https://www.youtube.com/embed/' + videoID;

                                    } else if ($scope.pitchDetails.video.indexOf("vimeo") > -1) {

                                        let videoID = videoSplit[videoSplit.length - 1];
                                        $scope.pitchDetails.video = 'https://player.vimeo.com/video/' + videoID;
                                    }
                                }
                            }

                            let end_date = moment($scope.pitchDetails.finish_date);
                            let todays_date = moment();
                            $scope.pitchDetails.days_left = end_date.diff(todays_date, 'days');

                            $scope.loading_page = false;

                            if (!!$scope.pitchDetails.social_media && !!$scope.pitchDetails.social_media) {
                                $scope.pitchDetails.social_media.short_blurb = $scope.trustHtml($scope.pitchDetails.social_media.short_blurb);
                            }

                            angular.forEach($scope.pitchDetails.company_overview, function (company) {
                                company.short_blurb = $scope.trustHtml(company.short_blurb);
                                company.long_blurb = $scope.trustHtml(company.long_blurb);
                            });

                            angular.forEach($scope.pitchDetails.customer_traction, function (customer) {
                                customer.short_blurb = $scope.trustHtml(customer.short_blurb);
                                customer.long_blurb = $scope.trustHtml(customer.long_blurb);
                            });

                            angular.forEach($scope.pitchDetails.financials, function (financial) {
                                financial.short_blurb = $scope.trustHtml(financial.short_blurb);
                                financial.long_blurb = $scope.trustHtml(financial.long_blurb);
                            });

                            angular.forEach($scope.pitchDetails.competitor_analysis, function (competitor) {
                                competitor.short_blurb = $scope.trustHtml(competitor.short_blurb);
                                competitor.long_blurb = $scope.trustHtml(competitor.long_blurb);
                            });

                            angular.forEach($scope.pitchDetails.team_members, function (team_member) {
                                team_member.short_blurb = $scope.trustHtml(team_member.short_blurb);
                            });

                        }
                    ).then(() => {
                        $timeout(function () {
                            let stickyNav = $('#pitch-menu').offset().top - 50;
                            $('#back-to-top').hide();

                            $(window).scroll(function () {
                                if ($(window).scrollTop() > stickyNav) {
                                    $('#pitch-menu').addClass('stick-pitch-nav');
                                    $('#back-to-top').show();
                                } else {
                                    $('#pitch-menu').removeClass('stick-pitch-nav');
                                    $('#back-to-top').hide();
                                }
                            });
                            $('.scrollspy').scrollSpy();
                        }, 0);

                        if ($scope.pitchDetails && $scope.pitchDetails.id) {
                            PitchService.retrievePitchInvestorsCount($scope.pitchDetails.id).then(res => {
                                $scope.pitchInvestors = res.data.investors;
                            });
                        }

                    });
                }
            ;

            init();

            $scope.invest = function () {

                if (!UserService.isAuthenticated()) {
                    return $state.transitionTo('signin', {
                        previousState: {
                            name: $state.current.name,
                            param_name: 'pitch_id',
                            param_id: pitchId
                        }
                    });
                }

                $state.transitionTo('pitch-funding.pledge', {pitch_id: pitchId});
            };

            $scope.authSignIn = function () {
                return $state.transitionTo('signin', {
                    previousState: {
                        name: $state.current.name,
                        param_name: 'pitch_id',
                        param_id: pitchId
                    }
                });
            };

            $scope.authSignUp = function () {
                return $state.transitionTo('signup', {
                    previousState: {
                        name: $state.current.name,
                        param_name: 'pitch_id',
                        param_id: pitchId
                    }
                });
            };

            $scope.backToTop = function () {
                $anchorScroll('top');
            };

            $scope.notAvailableModal = function () {
                $('#notAvailable').modal('open');
            };

        }
    )
;

