angular.module('uprise')

    .controller('SignUpUserController', function ($scope, $window, $rootScope, UserService, $state) {

        $scope.first_name = "";
        $scope.last_name = "";
        $scope.email_address = "";
        $scope.user_exists = false;
        $scope.submitting_form = false;

        let dataLayer = $window.dataLayer = $window.dataLayer || [];

        $scope.signUp = function ($event) {

            $scope.submitting_form = true;

            let payload = {
                first_name: $scope.first_name,
                last_name: $scope.last_name,
                email_address: $scope.email_address,
                password: $event.currentTarget.elements['password'].value // so we don't store the password on the scope
            };

            UserService.userSignup(payload).then((response) => {

                dataLayer.push({
                    'event': 'userRegistered',
                    'userId': response.data.user_id
                });

                $rootScope.$broadcast('handleLoginStateChangeBroadcast', response.data);
                if (localStorage.getItem('tmtData')) {
                    UserService.saveAdmarulaToken(localStorage.getItem('tmtData')).then(() => {
                        localStorage.removeItem('tmtData');
                    });
                }
                $state.transitionTo("investor_type");
                $scope.submitting_form = false;

            }, (response) => {
                console.log(response);
            });

        };

        $scope.joinWithFacebook = function () {
            $scope.submitting_form = true;
            FB.login(function (res) {
                let accessToken = res.authResponse.accessToken;
                if (res.status === 'connected') {
                    FB.api('/me', {fields: 'first_name, last_name, email'}, function (response) {
                        UserService.facebookUserSignUp(response, accessToken).then(res => {
                            $rootScope.$broadcast('handleLoginStateChangeBroadcast', res.data);
                            UserService.updateUserDetails(res.data);

                            dataLayer.push({
                                'event': 'userRegistered',
                                'userId': res.data.user_id
                            });

                            if (localStorage.getItem('tmtData')) {
                                UserService.saveAdmarulaToken(localStorage.getItem('tmtData')).then(() => {
                                    localStorage.removeItem('tmtData');
                                });
                            }
                            $state.transitionTo("investor_type");
                            $scope.submitting_form = false;
                        }).catch(function () {
                            $scope.user_exists = true;
                            $scope.submitting_form = false;
                        })
                    });
                }
                else {
                    console.log('[ERROR] User cancelled login or did not fully authorise (Facebook).')
                }
            }, {scope: 'email', return_scopes: true});
        };

        // Set the default value of inputType
        $scope.inputType = "password";

        // Hide & show password function
        $scope.hideShowPassword = function () {
            if ($scope.inputType == "password")
                $scope.inputType = "text";
            else
                $scope.inputType = "password";
        };

        $scope.options = {
            'width': 240,
            'height': 50,
            'longtitle': true,
            'onsuccess': function (response) {
                UserService.GoogleUserSignUp(response).then(res => {

                    dataLayer.push({
                        'event': 'userRegistered',
                        'userId': res.data.user_id
                    });

                    $rootScope.$broadcast('handleLoginStateChangeBroadcast', res.data);
                    UserService.updateUserDetails(res.data);
                    if (localStorage.getItem('tmtData')) {
                        UserService.saveAdmarulaToken(localStorage.getItem('tmtData')).then(() => {
                            localStorage.removeItem('tmtData');
                        });
                    }
                    $state.transitionTo("investor_type");
                    $scope.submitting_form = false;
                }).catch(function () {
                    $scope.user_exists = true;
                    $scope.submitting_form = false;
                });
            }
        };

    });