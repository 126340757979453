// A class for uploading files to the server via jQuery ajax, to prevent the need for form posts and page refreshes
// Currently python libraries for google cloud storage only accept files via form post, not json request, meaning that normal JSON requests via angular won't work

class Upload {

    constructor(file) {
        this.file = file;
    }

    getType() {
        if (!this.file) {
            return '';
        }
        return this.file.type;
    };

    getSize() {
        if (!this.file) {
            return 0;
        }
        return this.file.size;
    };

    getName() {
        return this.file.name;
    };

    doUpload() {

        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("upload_file", true);

        return new Promise((resolve, reject) => {

            $.ajax({
                type: "POST",
                url: "api/pitch/upload_doc",
                success: (data) => {
                    console.log(data);
                    resolve(data);
                },
                error: (error) => {
                    reject(error);
                },
                async: true,
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                timeout: 60000
            });

        });

    };

}


