angular.module('uprise')

    .factory('DiscoverService', function ($http) {

        let service = {

            query_string: '',

            searchPitches: function () {

                let instance = this;

                let req = {
                    method: 'POST',
                    url: `/api/pitch/search`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 60000,
                    data: {
                        'query_string': instance.query_string
                    }
                };

                return $http(req);
            },

        };

        return service;

    });
