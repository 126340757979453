angular.module('uprise')

    .factory('PitchFundingService', function ($http, $q) {

        let service = {

            retrievePitchIncompleteFunding: function (pitchId) {

                let req = {
                    method: 'GET',
                    url: `/api/pitch_funding/incomplete/${pitchId}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 60000,
                };

                return $http(req);


            },

            retrievePitchFunding: function (pitchFundingId) {

                let req = {
                    method: 'GET',
                    url: `/api/pitch_funding/pledge/${pitchFundingId}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 60000,
                };

                return $http(req);


            },

            addPitchFundingPledge: function (pitchId, pitchInvestmentOptionId, investmentPledged) {

                let req = {
                    method: 'POST',
                    url: '/api/pitch_funding/pledge/',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'pitch_id': pitchId,
                        'pitch_investment_option': pitchInvestmentOptionId,
                        'investment_pledged': investmentPledged
                    },
                    timeout: 60000,
                };

                return $http(req);


            },

            updatePitchFundingPledge: function (fundingId, pitchId, pitchInvestmentOptionId, investmentPledged) {

                let req = {
                    method: 'PUT',
                    url: `/api/pitch_funding/pledge/${fundingId}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'pitch_id': pitchId,
                        'pitch_investment_option': pitchInvestmentOptionId,
                        'investment_pledged': investmentPledged
                    },
                    timeout: 60000,
                };

                return $http(req);
            },

            updatePitchFundingBoardMember: function (board_member, fundingId) {
                let req = {
                    method: 'POST',
                    url: `/api/pitch_funding/board_member/${fundingId}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'board_member': board_member,
                    },
                    timeout: 60000,
                };

                return $http(req);
            },
            
            generateDocument: function (investmentPledged, pitchFundingId) {
                let req = {
                    method: 'POST',
                    url: `/api/pitch_funding/generate_document/${pitchFundingId}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'investment_pledged': investmentPledged,
                    },
                    timeout: 60000,
                };

                return $http(req);
            }

        };

        return service;

    });
