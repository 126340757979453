angular.module('uprise')

    .factory('UserService', function ($http, $q) {

        let userDetails = null;

        function setUserDetails(userObject) {
            userDetails = userObject;
        }

        var service = {

            userDetails: userDetails,

            isAuthenticated: () => {

                if (!userDetails || typeof userDetails.is_authenticated === "undefined") return false;
                return userDetails.is_authenticated;

            },

            getUserDetails: () => {
                var deferred = $q.defer();
                if (userDetails != null) {
                    deferred.resolve(userDetails);
                    return deferred.promise;
                }

                $http.get('/api/user').then(
                    response => {
                        setUserDetails(response.data);
                        deferred.resolve(userDetails);
                    },
                    error => {
                        console.log(error);
                        deferred.reject(error);
                    }
                );

                return deferred.promise;
            },

            checkUserSignedIn: function () {
                var deferred = $q.defer();

                this.getUserDetails().then(
                    response => {

                        if (response.is_authenticated) {
                            return deferred.resolve(response);
                        }

                        return deferred.resolve()

                    }, error => {
                        deferred.reject(error);
                    }
                );

                return deferred.promise;
            },
          updateUserDetails: function (user) {
               setUserDetails(user);
          },
            userSignup: function (formData) {
                var req = {
                    method: 'POST',
                    url: '/api/user/register',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 60000,
                    data: formData
                };

                return $http(req).then(
                    response => {
                        setUserDetails(response.data);
                        return response;
                    },
                    function errorHandler(response) {
                        console.log(response);
                    }
                );
            },

            userSignin: function (formData) {
                var req = {
                    method: 'POST',
                    url: '/api/user/login',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 60000,
                    data: formData
                };

                return $http(req).then(
                    response => {
                        setUserDetails(response.data);
                        return response;
                    },
                    function errorHandler(response) {
                        return $q.reject(response);
                    }
                );
            },

            userSignout: function () {
                var req = {
                    method: 'GET',
                    url: '/api/user/logout',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 60000
                };

                return $http(req).then(
                    response => {
                        setUserDetails(null);
                        return response;
                    },
                    function errorHandler(response) {
                        setUserDetails(null);
                        console.log(response);
                    }
                );
            },

            userForgotPassword: function (formData) {
                var req = {
                    method: 'POST',
                    url: '/api/user/forgot_password',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 60000,
                    data: formData

                };

                return $http(req).then(
                    response => {
                        setUserDetails(response.data);
                        return response;
                    },
                    function errorHandler(response) {
                        console.log(response);
                    }
                );
            },

            userResetPassword: function (formData) {
                var req = {
                    method: 'POST',
                    url: '/api/user/reset_password',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 60000,
                    data: formData

                };

                return $http(req).then(
                    response => {
                        setUserDetails(response.data);
                        return response;
                    },
                    function errorHandler(response) {
                        console.log(response);
                    }
                );
            },

            setUserInvestorType: (selectedUserType, investorType) => {

                let req = {
                    method: 'POST',
                    url: '/api/user/set_investor_type',
                    timeout: 60000,
                    data: {
                        'investor_type': investorType,
                        'user_type': selectedUserType,
                    }
                };

                return $http(req);
            },

            setUserType: (selectedUserType) => {

                let req = {
                    method: 'POST',
                    url: '/api/user/set_user_type',
                    timeout: 60000,
                    data: {
                        'user_type': selectedUserType,
                    }
                };

                return $http(req);
            },

            setUserInvestmentExperience: (previousInvestmentExperience, previousInvestments, investorType) => {

                let req = {
                    method: 'POST',
                    url: '/api/user/set_investment_experience',
                    timeout: 60000,
                    data: {
                        previous_investment_experience: previousInvestmentExperience,
                        previous_investments: previousInvestments,
                        investor_type: investorType,
                    }
                };

                return $http(req);
            },

            setUserKYCDetails: (investorDetails, pitchId) => {

                let req = {
                    method: 'POST',
                    url: '/api/user/set_kyc_details',
                    timeout: 60000,
                    data: {
                        investor: investorDetails,
                        pitch_id: pitchId,
                    }
                };

                return $http(req);
            },

            fetchUserProfile: (user_id) => {
                let req = {
                    method: 'POST',
                    url: '/api/user/fetch_user_profile',
                    timeout: 60000,
                    data: {
                        user_id: user_id
                    }
                };

                return $http(req);
            },

            editProfile: (email_address, investor_type) => {
                let req = {
                    method: 'POST',
                    url: '/api/user/edit',
                    timeout: 60000,
                    data: {
                        email_address: email_address,
                        investor_type: investor_type,
                    }
                };

                return $http(req);
            },

            verifyUserProfile: (redirect_user_url) => {
                let req = {
                    method: 'POST',
                    url: '/api/user/email_address_verification',
                    timeout: 60000,
                    data: {
                        redirect_user_url: redirect_user_url,
                    }
                };

                return $http(req);
            },

            changePassword: (email_address) => {
                let req = {
                    method: 'POST',
                    url: '/api/user/forgot_password',
                    timeout: 60000,
                    data: {
                        email_address: email_address,
                    }
                };

                return $http(req);
            },

            facebookUserSignUp: (facebook, access_token) => {
                let req = {
                    method: 'POST',
                    url: '/api/user/facebook_user_signup',
                    timeout: 60000,
                    data: {
                        first_name: facebook.first_name,
                        last_name: facebook.last_name,
                        email_address: facebook.email,
                        access_token: access_token,
                    }
                };

                return $http(req);
            },

            facebookUserSignIn: (access_token) => {
                let req = {
                    method: 'POST',
                    url: '/api/user/facebook_user_sign_in',
                    timeout: 60000,
                    data: {
                        access_token: access_token,
                    }
                };

                return $http(req);
            },

            GoogleUserSignUp: (google) => {
                let req = {
                    method: 'POST',
                    url: '/api/user/google_user_signup',
                    data: {
                        google: google,
                    }
                };

                return $http(req);
            },

            GoogleUserSignIn: (google) => {
                let req = {
                    method: 'POST',
                    url: '/api/user/google_user_sign_in',
                    data: {
                        google: google,
                    }
                };

                return $http(req);
            },

            newsletterSignUp: (email_address) => {
                let req = {
                    method: 'POST',
                    url: '/api/user/newsletter_signup',
                    data: {
                        email_address: email_address,
                    }
                };

                return $http(req);
            },

            forgotPassword: (email_address) => {
                let req = {
                    method: 'POST',
                    url: '/api/user/forgot_password',
                    data: {
                        email_address: email_address,
                    }
                };

                return $http(req);
            },

            fetchGoogleClientId: () => {
                return $http.get('/api/user/fetch_google_client_id')
            },

            FetchUserDetails: () => {
                return $http.get('/api/user/fetch_user_details')
            },

            saveAdmarulaToken: (admarula_token) => {
                let req = {
                    method: 'POST',
                    url: '/api/user/save_admarula_token',
                    data: {
                        admarula_token: admarula_token,
                    }
                };

                return $http(req);
            },

            verifyToken: (user_id, token) => {
                let req = {
                    method: 'POST',
                    url: '/api/user/verify_token',
                    data: {
                        user_id: user_id,
                        token: token,
                    }
                };

                return $http(req);
            },

            redirectUser: (user_id) => {
                let req = {
                    method: 'POST',
                    url: '/api/user/redirect_user_after_verification',
                    data: {
                        user_id: user_id,
                    }
                };

                return $http(req);
            }

        };

        service.getUserDetails();

        return service;

    });
