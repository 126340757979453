angular.module('uprise')

    .controller('UserWelcomeController', function ($scope, $state, UserService, PitchSetupService) {

        let init = function () {
            UserService.FetchUserDetails().then(res => {
                $scope.user = res.data.user_info;
            })
        };

        init();

        $scope.createPitch = function () {
            PitchSetupService.createPitch().then(res => {
                $state.transitionTo('create-pitch.details', {pitch_id: res.data.id});
            });
        };

    });