angular.module('uprise')
    .controller('PitchFundingPledgeController', function ($scope, $state, $location, $timeout, $window, PitchService, UserService, PitchFundingService, PitchSetupService) {
        $scope.loading_page = true;
        $scope.email_verification_sent = false;
        let pitchId = $state.params.pitch_id;

        let dataLayer = $window.dataLayer = $window.dataLayer || [];

        switch ($state.current.name) {
            case 'pitch-funding.pledge':
                $scope.step = 1;
                break;
            case 'pitch-funding.assessment':
                $scope.step = 2;
                break;
            case 'pitch-funding.your_details':
                $scope.step = 3;
                break;
            case 'pitch-funding.disclaimer':
                $scope.step = 4;
                break;
            case 'pitch-funding-pledge-payment':
                $scope.step = 5;
                break;
            case 'pitch-funding.agreement':
                $scope.step = 6;
                break;
        }

        let userProfileDetails = {};
        $scope.pitchFundingId = 0;
        $scope.investorType = '';
        $scope.selectedInvestmentOption = {};
        $scope.pitchDetails = {};
        $scope.errorMessage = "";
        $scope.investmentOptions = [];
        $scope.continuedToAgreement = false;
        $scope.answersSelectedCorrectly = false;

        //variables used by tabs navigation => do not allow user to skip steps where applicable
        $scope.pledgeComplete = false;
        $scope.experienceAssessmentComplete = false;
        $scope.detailsCaptured = false;

        $scope.selectInvestorType = function (type) {
            $scope.investorType = type;
        };

        $scope.investor = {
            previous_investment_experience: null,
            id_number: null,
            identity_document: '',
            street_name_and_number: '',
            apartment_name_and_number: '',
            suburb: '',
            postcode: '',
            first_name: '',
            last_name: '',
            middle_name: '',
            cellphone: '',
            proof_of_address: null,
            accept_t_and_c: false,
            has_green_bar_coded_id_book: null,
            country_of_residence: '',
            pitch_investment_option_id: '',
            pitch_investment_pledged: 0,
            investment_knowledge_assessment: false,
            source_of_funds: '',
            board_member: false
        };

        const previousExperienceOptions = [
            {
                title: 'Started a business',
                selected: false
            },
            {
                title: 'Bought investment property',
                selected: false
            },
            {
                title: 'Completed a e-commerce related degree',
                selected: false
            },
            {
                title: 'Invested in equity',
                selected: false
            }
        ];

        const testQuestions = [
            {
                title: 'What happens to most small businesses?',
                answers: [{
                    title: 'They fail',
                    selected: false,
                    is_correct: true,
                    response_message: 'That’s right, in South Africa 4 out of 5 new businesses fail within in the first 2 years.'
                },
                    {
                        title: 'They are a huge success and make their investors tons of money',
                        selected: false,
                        is_correct: false,
                        response_message: 'That is incorrect, in South Africa 4 out of 5 new businesses fail within in the first 2 years.'
                    }]
            },
            {
                title: 'If the startup I invest in fails, what happens?',
                answers: [{
                    title: 'Uprise.Africa will pay back all the money I invested',
                    selected: false,
                    is_correct: false,
                    response_message: 'That is incorrect if the business fails you will not be able to recover your investment.'
                },
                    {
                        title: 'It is unlikely that I will get my investment back.',
                        selected: false,
                        is_correct: true,
                        response_message: 'That’s right if the business fails you will not be able to recover your investment.'
                    }
                ]
            },
            {
                title: 'After I have been issued shares, can I get my money back at any time?',
                answers: [{
                    title: 'No, it typically will not be easy for me to sell my shares unless the company I have invested in has been bought or sits on the stock markets.',
                    selected: false,
                    is_correct: true,
                    response_message: 'That is correct; small business shares can rarely be sold unless it has been listed on the stock market or exited.'
                },
                    {
                        title: 'Yes, legally the company must pay my money back whenever I want it.',
                        selected: false,
                        is_correct: false,
                        response_message: 'That is incorrect; small business shares can rarely be sold unless it has been listed on the stock market or exited.'
                    }
                ]
            },
            {
                title: 'Do startups pay dividends?',
                answers: [{
                    title: 'Yes, I can expect payment of dividends periodically.',
                    selected: false,
                    is_correct: false,
                    response_message: 'That is incorrect; start-ups are unlikely to pay periodic dividends.'
                },
                    {
                        title: 'No, startups are not likely to pay dividends.',
                        selected: false,
                        is_correct: true,
                        response_message: 'That is correct; start-ups are unlikely to pay periodic dividends.'
                    }
                ]
            },
            {
                title: 'If the company I invest in is successful, can I sell my shares?',
                answers: [{
                    title: 'Yes, The founders of the company must buy back my shares by law.',
                    selected: false,
                    is_correct: false,
                    response_message: 'That is incorrect; business owners are not obliged to buy back investor shares.'
                },
                    {
                        title: 'Generally shares purchased via Equity Crowdfunding are liquid, however a liquidity event will vary based on the deal - Each deals liquidity information will be displayed on their pitch page.',
                        selected: false,
                        is_correct: true,
                        response_message: 'That is correct; small business shares can rarely be sold unless it has been listed on the stock market or exited.'
                    }
                ]
            }

        ];

        $scope.investmentValidation = {
            previousExperienceOptions: previousExperienceOptions,
            testQuestions: testQuestions,
            idNumberValid: null
        };

        /** SECTION 1 : SELECT INVESTMENT OPTION **/

        let init = function () {
            if (!!pitchId) {

                UserService.checkUserSignedIn().then(res => {

                    if (res) {
                        //yes, signed in, retrieve their profile details
                        UserService.fetchUserProfile(res.user_id).then(res => {
                            userProfileDetails = res.data.user_info;
                            $scope.investor.first_name = userProfileDetails.first_name;
                            $scope.investor.last_name = userProfileDetails.last_name;
                            $scope.investor.middle_name = userProfileDetails.middle_name;
                            $scope.investorType = userProfileDetails.investor_type;

                            //used by navigator tabs
                            $scope.experienceAssessmentComplete = userProfileDetails.investment_knowledge_assessment;
                            $scope.detailsCaptured = !!userProfileDetails.investment_knowledge_assessment && !!userProfileDetails.proof_of_address_url && !!userProfileDetails.id_document_url && !!userProfileDetails.suburb;

                            // if (!userProfileDetails.investor_type) {
                            //     $state.transitionTo('investor_type');
                            // }

                        });
                    }

                });

                //step 1 : retrieve the different investment options for this pitch
                PitchService.retrievePitchInvestmentOptions(pitchId).then(res => {

                    $scope.investmentOptions = res.data.investment_options;

                    PitchFundingService.retrievePitchIncompleteFunding(pitchId).then(res2 => {

                        //step 2 : check if user already has started a pledge for this pitch
                        if (!!res2 && !!res2.data && typeof(res2.data.pitch_investment_option) != 'undefined') {

                            if (!!res2.data.investor) {

                                //the user has already started the investment journey for this pitch, but has not completed it
                                $scope.investor.previous_investment_experience = res2.data.investor.previous_investment_experience;
                                $scope.investor.investing_as = res2.data.investor.investing_as;
                                $scope.investor.cellphone = res2.data.investor.cellphone;

                                $scope.investor.id_number = res2.data.investor.id_number;
                                $scope.investor.has_green_bar_coded_id_book = res2.data.investor.has_green_bar_coded_id_book || null;
                                $scope.investor.country_of_residence = res2.data.investor.country_of_residence || '';

                                $scope.investmentValidation.idNumberValid = true;
                                $scope.investor.identity_document = res2.data.investor.id_document_url;
                                $scope.investor.street_name_and_number = res2.data.investor.street_name_and_number;
                                $scope.investor.apartment_name_and_number = res2.data.investor.apartment_name_and_number;
                                $scope.investor.suburb = res2.data.investor.suburb;
                                $scope.investor.postcode = res2.data.investor.postcode;
                                $scope.investor.proof_of_address = res2.data.investor.proof_of_address_url;
                                $scope.investor.company_cert = res2.data.investor.company_cert;
                                $scope.investor.investment_knowledge_assessment = res2.data.investor.investment_knowledge_assessment;
                                $scope.investor.source_of_funds = res2.data.investor.source_of_funds;

                                $scope.investor.company_cert_doc = res2.data.investor.company_cert_doc;
                                $scope.investor.company_registration_number = res2.data.investor.company_registration_number;
                                $scope.investor.company_registered_name = res2.data.investor.company_registered_name;
                                $scope.investor.director_contact_number = res2.data.investor.director_contact_number;

                                $scope.investor.trust_name = res2.data.investor.trust_name;
                                $scope.investor.trust_contact_number = res2.data.investor.trust_contact_number;
                                $scope.investor.trust_deeds = res2.data.investor.trust_deeds;
                                $scope.investor.trust_letter_of_authority = res2.data.investor.trust_letter_of_authority;
                                $scope.investor.trust_changes = res2.data.investor.trust_changes;
                                $scope.investor.trust_owner_ids = res2.data.investor.trust_owner_ids;
                                $scope.investor.trust_proof_of_address = res2.data.investor.trust_proof_of_address;
                                $scope.investor.trust_confirm_location = res2.data.investor.trust_confirm_location;
                            }

                            $scope.pitchFundingId = res2.data.id;
                            $scope.investor.pitch_investment_option_id = res2.data.pitch_investment_option.id;
                            $scope.investor.pitch_investment_pledged = res2.data.investment_pledged;
                            $scope.selectedInvestmentOption = res2.data.pitch_investment_option;
                            $scope.pledgeComplete = (!!$scope.investor.pitch_investment_pledged && !!res2.data.pitch_investment_option);

                        } else {
                            setDefaultInvestment();
                        }

                        //step 3: fetch the pitch details
                        PitchSetupService.fetchPitchDetails(pitchId).then(res3 => {
                            $scope.pitchDetails = res3.data;
                            let end_date = moment($scope.pitchDetails.finish_date);
                            let todays_date = moment();
                            $scope.pitchDetails.days_left = end_date.diff(todays_date, 'days');
                        });

                        $scope.loading_page = false;

                    });

                }).catch(exception => {
                    console.log(exception);
                })

            }

            $(document).ready(function () {
                $('.modal').modal();
            });

        };


        function setDefaultInvestment() {

            if (!!$scope.investmentOptions && $scope.investmentOptions.length > 0) {

                $scope.investor.pitch_investment_option_id = $scope.investmentOptions[0].id;
                $scope.investor.pitch_investment_pledged = $scope.investmentOptions[0].minimum_investment;
                $scope.selectedInvestmentOption = $scope.investmentOptions[0];
            }

        }

        init();

        $scope.verifyUserProfile = function () {
            UserService.verifyUserProfile($location.path()).then(() => {
                $timeout(function () {
                    $scope.email_verification_sent = true;
                }, 0);
            });
        };

        //this is invoked when a user selects an investment-type radio button
        $scope.setInvestmentOption = function (selectedInvestmentOptionId) {

            $scope.investor.pitch_investment_option_id = selectedInvestmentOptionId;

            $scope.investmentOptions.forEach(option => {
                if (option.id === selectedInvestmentOptionId) {

                    //set the default investment amount to the minimum amount of this share type
                    if ($scope.pitchFundingId === 0) {
                        $scope.investor.pitch_investment_pledged = option.minimum_investment;
                    }


                    $scope.selectedInvestmentOption = option;
                }
            });
        };

        //this is invoked when the investor selects which share they want to invest in (i.e. clicks 'continue' on step 1)
        $scope.pledgeInvestment = function () {

            $('.modal').modal();

            dataLayer.push({
                'event': 'step_chooseamount',
                'pitchId': pitchId,
                'userId': userProfileDetails.id,
                'amt': $scope.investor.pitch_investment_pledged
            });

            if (userProfileDetails.email_verified) {
                saveInvestment().then(res => {

                    $scope.pledgeComplete = true;

                    if (!userProfileDetails.investment_knowledge_assessment) {
                        $scope.step = 2;
                        $state.transitionTo('pitch-funding.assessment', {pitch_id: pitchId});
                        return;
                    }

                    if (userProfileDetails.investment_knowledge_assessment && !userProfileDetails.proof_of_address_url && !userProfileDetails.id_document_url && !userProfileDetails.suburb) {
                        $scope.step = 3;
                        $state.transitionTo('pitch-funding.your_details', {pitch_id: pitchId});
                        return;
                    }

                    if (userProfileDetails.proof_of_address_url && userProfileDetails.id_document_url && userProfileDetails.suburb) {
                        $scope.step = 4;
                        $state.transitionTo('pitch-funding.disclaimer', {pitch_id: pitchId});
                        return;
                    }

                    if (userProfileDetails.proof_of_address_url && userProfileDetails.suburb) {
                        $scope.step = 4;
                        $state.transitionTo('pitch-funding.disclaimer', {pitch_id: pitchId});
                        return;
                    }

                }, error => {

                    console.log(error);
                    $scope.errorMessage = error;
                    $scope.$apply();
                    $('#modalInvestmentError').modal('open');

                });
            } else {
                $('#emailAddressNotVerified').modal('open');
            }
        };

        $scope.navigateToProfile = function () {
            $('#emailAddressNotVerified').modal('close');
            $state.transitionTo('user-profile', {user_id: userProfileDetails.id})
        };

        //this is invoked when the investor selects which share they want to invest in (i.e. clicks 'save and exit')
        $scope.pledgeAndExit = function ($event) {

            $event.preventDefault();

            saveInvestment().then(res => {
                $state.transitionTo('home');
            }, error => {

                console.log(error);
                $scope.errorMessage = error;
                $scope.$apply();
                $('#modalInvestmentError').modal('open');

            });

        };

        //set class on tab navigator buttons
        $scope.setActiveClass = function (i) {

            if (i === 2 && $scope.pledgeComplete === false) {
                return 'inactive';
            }
            if (i === 3 && $scope.experienceAssessmentComplete === false) {
                return 'inactive';
            }
            if (i === 4 && $scope.detailsCaptured === false) {
                return 'inactive';
            }
            if (i === 5 && ($scope.pledgeComplete === false
                || $scope.experienceAssessmentComplete === false
                || $scope.detailsCaptured === false
                || !$scope.investor.accept_t_and_c)) {
                return 'inactive';
            }
            if (i === 6 && ($scope.pledgeComplete === false
                || $scope.experienceAssessmentComplete === false
                || $scope.detailsCaptured === false
                || $scope.continuedToAgreement === false)) {
                return 'inactive';
            }

            if (i === $scope.step) {
                return 'active';
            }
            return '';
        };

        $scope.backToPitch = function () {
            $state.transitionTo('view-pitch-details', {pitch_id: pitchId});
        };

        //click on tab navigator button
        $scope.selectStep = function ($event, i) {

            if (userProfileDetails.email_verified) {
                $event.preventDefault();

                switch (i) {

                    case 1:
                        $scope.step = 1;
                        $state.transitionTo('pitch-funding.pledge', {pitch_id: pitchId});
                        break;

                    case 2:

                        if (!$scope.pledgeComplete) {
                            return;
                        }

                        $scope.step = 2;
                        $state.transitionTo('pitch-funding.assessment', {pitch_id: pitchId});
                        break;

                    case 3:

                        if (!$scope.pledgeComplete || !$scope.experienceAssessmentComplete) {
                            return;
                        }

                        $scope.step = 3;
                        $state.transitionTo('pitch-funding.your_details', {pitch_id: pitchId});
                        break;

                    case 4:

                        if (!$scope.pledgeComplete || !$scope.experienceAssessmentComplete || !$scope.detailsCaptured) {
                            return;
                        }

                        $scope.step = 4;
                        $state.transitionTo('pitch-funding.disclaimer', {pitch_id: pitchId});
                        break;

                    case 5:

                        if (!$scope.pledgeComplete || !$scope.experienceAssessmentComplete || !$scope.detailsCaptured || !userProfileDetails.accept_t_and_c) {
                            return;
                        }

                        $scope.step = 5;
                        $state.transitionTo('pitch-funding-pledge-payment', {pitch_id: pitchId});
                        break;

                    case 6:
                        if (!$scope.pledgeComplete || !$scope.experienceAssessmentComplete || !$scope.detailsCaptured || !$scope.continuedToAgreement) {
                            return;
                        }

                        $scope.step = 6;
                        $state.transitionTo('pitch-funding.agreement', {pitch_id: pitchId});
                        break;

                }
            }

        };

        $scope.selectedOption = function (optionId) {
            return parseInt($scope.investor.pitch_investment_option_id) == parseInt(optionId) ? 'selected-option' : '';
        };

        function saveInvestment() {

            return new Promise((resolve, reject) => {

                    let shareCapital = $scope.investor.pitch_investment_pledged && !isNaN($scope.investor.pitch_investment_pledged) ? parseFloat($scope.investor.pitch_investment_pledged) : 0; //amount they want to invest
                    let fundingGoalCapital = $scope.pitchDetails.funding_goal_capital || 1; //amount company is raising
                    let fundingGoalEquity = $scope.pitchDetails.funding_goal_equity || 0; //equity company is giving away for this amount they are raising
                    let maxEquityAvailable = $scope.pitchDetails.max_equity_available || 0; //the max amount of equity they are prepared to give away
                    let equityDivested = $scope.pitchDetails.equity_reserved || 0; //amount of equity given away so far
                    let equityPurchased = (fundingGoalEquity / fundingGoalCapital) * shareCapital; //amount of equity this user will receive for their investment

                    if (equityPurchased + equityDivested > maxEquityAvailable) {
                        reject('Unfortunately the investment pledged exceeds amount of remaining equity available.');
                        return;
                    }

                    let shareRandValue = 0;

                    for (let i = 0; i < $scope.investmentOptions.length; i++) {
                        if ($scope.investmentOptions[i].id === $scope.investor.pitch_investment_option_id) {

                            //share value should never be 0!
                            shareRandValue = $scope.investmentOptions[i].rand_value_per_share || 0;
                            if (shareRandValue === 0) {
                                reject('Unfortunately there an error has occurred, please contact Uprise.');
                            }

                            //check min or max investment limits not exceeded
                            if (shareCapital < $scope.investmentOptions[i].minimum_investment) {
                                reject(`Unfortunately the investment pledged does not meet minimum permitted amount. Please note that the minimum investment amount for the selected share type is 
                                R${$scope.investmentOptions[i].minimum_investment}.`);
                                return;
                            } else if (shareCapital > $scope.investmentOptions[i].maximum_investment) {
                                reject(`Unfortunately the investment pledged exceeds maximum permitted amount. Please note that the maximum investment amount for the selected share type is 
                                R${$scope.investmentOptions[i].maximum_investment}.`);
                                return;
                            }

                            //check for round share amount (cannot receive fraction of a share)
                            if (shareCapital % shareRandValue !== 0) {

                                while (shareCapital % shareRandValue !== 0) {
                                    shareCapital -= 1;
                                }

                                const sharesReceivedForInvestmentMade = shareCapital / shareRandValue;
                                $scope.investor.pitch_investment_pledged = shareCapital;

                                reject(`We have automatically decreased your investment amount to R${shareCapital}, to ensure that the number of shares you receive is a round number i.e. ${sharesReceivedForInvestmentMade}. 
                                Please confirm that this is alright by again clicking the 'Continue' button.`);
                                return;

                            }

                        }
                    }

                    if ($scope.pitchFundingId === 0) {
                        //this is a new funding
                        resolve(PitchFundingService.addPitchFundingPledge(pitchId,
                            $scope.investor.pitch_investment_option_id,
                            $scope.investor.pitch_investment_pledged));
                    } else {
                        //this is an existing funding
                        resolve(PitchFundingService.updatePitchFundingPledge($scope.pitchFundingId,
                            pitchId,
                            $scope.investor.pitch_investment_option_id,
                            $scope.investor.pitch_investment_pledged));
                    }

                }
            );

        };


        /** SECTION 2 : SPECIFY INVESTMENT EXPERIENCE **/

        $scope.setAnswer = function (questionIndex, answerIndex) {
            $scope.investmentValidation.testQuestions[questionIndex].answers.forEach(answer => {
                answer.selected = false;
            });
            $scope.investmentValidation.testQuestions[questionIndex].answers[answerIndex].selected = true;

            let isValid = true;
            $scope.investmentValidation.testQuestions.forEach(question => {
                question.answers.forEach(answer => {
                    if (!answer.selected && answer.is_correct) {
                        isValid = false;
                    }
                });

            });

            if (isValid) {
                $scope.answersSelectedCorrectly = true;
            }
        };

        //Save the user's investment experience to the database
        $scope.saveInvestmentExperience = function (exit) {

            dataLayer.push({
                'event': 'step_assessment',
                'pitchId': pitchId,
                'userId': userProfileDetails.id,
                'amt': $scope.investor.pitch_investment_pledged
            });

            //do not allow them to continue if they answer the questions incorrectly
            let isValid = true;
            $scope.investmentValidation.testQuestions.forEach(question => {

                question.answers.forEach(answer => {
                    if (!answer.selected && answer.is_correct) {
                        isValid = false;
                    }
                });

            });

            if (!isValid) {
                $scope.errorMessage = "Please ensure you have responded correctly to the questions in the 'Your knowledge' section.";
                $('#modalInvestmentError').modal('open');
                return;
            }

            if (!$scope.investor) {
                $scope.errorMessage = "Please indicate if you have any investment experience.";
                $('#modalInvestmentError').modal('open');
                return;
            }

            $scope.investor.previous_investment_experience = !!$scope.investor.previous_investment_experience; // null or undefined becomes false

            //retrieve their previous investment experience
            let previousInvestments = [];
            $scope.investmentValidation.previousExperienceOptions.forEach(item => {
                if (item.selected) {
                    previousInvestments.push(item.title);
                }
            });

            //invoke API to save
            UserService.setUserInvestmentExperience($scope.investor.previous_investment_experience,
                previousInvestments.join(','), $scope.investorType).then(() => {

                //mark experience assessment as completed => affects tab navigator
                $scope.experienceAssessmentComplete = true;
                $scope.step = 3;

                if (exit) {
                    $state.transitionTo('home');
                } else {
                    $state.transitionTo('pitch-funding.your_details', {pitch_id: pitchId});
                }

            });

        };


        /** SECTION 3 : UPDATE INVESTOR KYC DATA **/

        $scope.validateSAIdentityNumber = function () {

            console.log('validateSAIdentityNumber');
            if (!$scope.investor || !$scope.investor.id_number) {
                $scope.investmentValidation.idNumberValid = false;
                return false;
            }

            const id_number = $scope.investor.id_number + ''; //convert it into a string

            //1. numeric and 13 digits
            if (isNaN(id_number) || (id_number.length != 13)) {
                $scope.investmentValidation.idNumberValid = false;
                return false;
            }
            //2. first 6 numbers is a valid date
            let date = new Date(parseInt(id_number.substring(0, 2)), parseInt(id_number.substring(2, 4)) - 1, parseInt(id_number.substring(4, 6)));

            if (date.getYear() !== parseInt(id_number.substring(0, 2)) ||
                date.getMonth() !== parseInt(id_number.substring(2, 4)) - 1 ||
                date.getDate() !== parseInt(id_number.substring(4, 6))) {
                $scope.investmentValidation.idNumberValid = false;
                return false;
            }

            //3. luhn formula
            let tempTotal = 0;
            let checkSum = 0;
            let multiplier = 1;
            for (let i = 0; i < 13; ++i) {
                tempTotal = parseInt(id_number.charAt(i)) * multiplier;
                if (tempTotal > 9) {
                    tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
                }
                checkSum = checkSum + tempTotal;
                multiplier = (multiplier % 2 == 0) ? 1 : 2;
            }
            if ((checkSum % 10) == 0) {
                $scope.investmentValidation.idNumberValid = true;
                return true;
            }
            $scope.investmentValidation.idNumberValid = false;
            return false;

        }

        $scope.idDocumentUploaded = function (obj, event) {

            const item = event.target;
            const file = $(item)[0].files[0];
            const upload = new Upload(file);

            jQuery('#id_upload_progress_parent').toggle();

            // TODO: check size or type here with upload.getSize() and upload.getType()
            upload.doUpload().then((docUrl) => {
                jQuery('#id_upload_progress_parent').toggle();
                $scope.investor.identity_document = docUrl;
                $scope.$apply();
            }, err => {
                jQuery('#id_upload_progress_parent').toggle();
                console.log(err);
            });
        };

        $scope.addressDocumentUploaded = function (obj, event) {

            const item = event.target;
            const file = $(item)[0].files[0];
            const upload = new Upload(file);

            jQuery('#address_upload_progress_parent').toggle();

            // TODO: check size or type here with upload.getSize() and upload.getType()
            upload.doUpload().then((docUrl) => {
                jQuery('#address_upload_progress_parent').toggle();
                $scope.investor.proof_of_address = docUrl;
                $scope.$apply();
            }, err => {
                jQuery('#address_upload_progress_parent').toggle();
                console.log(err);
            });
        };

        $scope.companyCertDocumentUploaded = function (obj, event) {

            const item = event.target;
            const file = $(item)[0].files[0];
            const upload = new Upload(file);

            jQuery('#company_cert_upload_progress_parent').toggle();

            // TODO: check size or type here with upload.getSize() and upload.getType()
            upload.doUpload().then((docUrl) => {
                jQuery('#company_cert_upload_progress_parent').toggle();
                $scope.investor.company_cert = docUrl;
                $scope.$apply();
            }, err => {
                jQuery('#company_cert_upload_progress_parent').toggle();
                console.log(err);
            });
        };

        $scope.trustDeedsDocumentUploaded = function (obj, event) {

            const item = event.target;
            const file = $(item)[0].files[0];
            const upload = new Upload(file);

            jQuery('#trust_deeds_upload_progress_parent').toggle();

            // TODO: check size or type here with upload.getSize() and upload.getType()
            upload.doUpload().then((docUrl) => {
                jQuery('#trust_deeds_upload_progress_parent').toggle();
                $scope.investor.trust_deeds = docUrl;
                $scope.$apply();
            }, err => {
                jQuery('#trust_deeds_upload_progress_parent').toggle();
                console.log(err);
            });
        };

        $scope.trustLetterOfAuthorityDocumentUploaded = function (obj, event) {

            const item = event.target;
            const file = $(item)[0].files[0];
            const upload = new Upload(file);

            jQuery('#trust_letter_of_authority_upload_progress_parent').toggle();

            // TODO: check size or type here with upload.getSize() and upload.getType()
            upload.doUpload().then((docUrl) => {
                jQuery('#trust_letter_of_authority_upload_progress_parent').toggle();
                $scope.investor.trust_letter_of_authority = docUrl;
                $scope.$apply();
            }, err => {
                jQuery('#trust_letter_of_authority_upload_progress_parent').toggle();
                console.log(err);
            });
        };

        $scope.trustChangesDocumentUploaded = function (obj, event) {

            const item = event.target;
            const file = $(item)[0].files[0];
            const upload = new Upload(file);

            jQuery('#trust_changes_upload_progress_parent').toggle();

            // TODO: check size or type here with upload.getSize() and upload.getType()
            upload.doUpload().then((docUrl) => {
                jQuery('#trust_changes_upload_progress_parent').toggle();
                $scope.investor.trust_changes = docUrl;
                $scope.$apply();
            }, err => {
                jQuery('#trust_changes_upload_progress_parent').toggle();
                console.log(err);
            });
        };

        $scope.trustOwnerIdDocumentUploaded = function (obj, event) {

            const item = event.target;
            const file = $(item)[0].files[0];
            const upload = new Upload(file);

            jQuery('#trust_owner_ids_upload_progress_parent').toggle();

            // TODO: check size or type here with upload.getSize() and upload.getType()
            upload.doUpload().then((docUrl) => {
                jQuery('#trust_owner_ids_upload_progress_parent').toggle();
                $scope.investor.trust_owner_ids = docUrl;
                $scope.$apply();
            }, err => {
                jQuery('#trust_owner_ids_upload_progress_parent').toggle();
                console.log(err);
            });
        };

        $scope.trustProofOfAddressDocumentUploaded = function (obj, event) {

            const item = event.target;
            const file = $(item)[0].files[0];
            const upload = new Upload(file);

            jQuery('#trust_proof_of_address_upload_progress_parent').toggle();

            // TODO: check size or type here with upload.getSize() and upload.getType()
            upload.doUpload().then((docUrl) => {
                jQuery('#trust_proof_of_address_upload_progress_parent').toggle();
                $scope.investor.trust_proof_of_address = docUrl;
                $scope.$apply();
            }, err => {
                jQuery('#trust_proof_of_address_upload_progress_parent').toggle();
                console.log(err);
            });
        };

        $scope.trustConfirmLocationDocumentUploaded = function (obj, event) {

            const item = event.target;
            const file = $(item)[0].files[0];
            const upload = new Upload(file);

            jQuery('#trust_confirm_location_upload_progress_parent').toggle();

            // TODO: check size or type here with upload.getSize() and upload.getType()
            upload.doUpload().then((docUrl) => {
                jQuery('#trust_confirm_location_upload_progress_parent').toggle();
                $scope.investor.trust_confirm_location = docUrl;
                $scope.$apply();
            }, err => {
                jQuery('#trust_confirm_location_upload_progress_parent').toggle();
                console.log(err);
            });
        };

        $scope.setKYCDetails = function (exit) {

            dataLayer.push({
                'event': 'step_details',
                'pitchId': pitchId,
                'userId': userProfileDetails.id,
                'amt': $scope.investor.pitch_investment_pledged
            });

            if ($scope.investor.investing_as === 'individual' || $scope.investor.investing_as === 'sa_company') {
                if (!$scope.investor.id_number
                    || !$scope.investor.identity_document
                    || !$scope.investor.street_name_and_number
                    || !$scope.investor.proof_of_address) {
                    $scope.errorMessage = "Please ensure that required fields are completed, and that all requested documents are uploaded.";
                    $('#modalInvestmentError').modal('open');
                    return;
                }

                if (!$scope.investmentValidation.idNumberValid && $scope.investor.has_green_bar_coded_id_book === true) {
                    $scope.errorMessage = "Please enter a valid ID number.";
                    $('#modalInvestmentError').modal('open');
                    return;
                }
            }


            UserService.setUserKYCDetails($scope.investor, pitchId).then(() => {

                //mark KYC details as completed => affects tab navigator
                $scope.detailsCaptured = true;
                $scope.step = 4;

                if (exit) {
                    $state.transitionTo('home');
                } else {
                    $state.transitionTo('pitch-funding.disclaimer', {pitch_id: pitchId});
                }
            });

        };

        /** SECTION 4 : DISCLAIMERS **/

        $scope.continueToAgreement = function () {

            dataLayer.push({
                'event': 'step_disclaimer',
                'pitchId': pitchId,
                'userId': userProfileDetails.id,
                'amt': $scope.investor.pitch_investment_pledged
            });

            if (!$scope.investor.accept_t_and_c) {
                $scope.errorMessage = "Please confirm you have read and agree to these terms and conditions";
                $('#modalInvestmentError').modal('open');
                return;
            }

            $scope.continuedToAgreement = true;

            $state.transitionTo('pitch-funding-pledge-agreement', {pitch_id: pitchId});

        };

    });