angular.module('uprise')
    .controller('PitchFundingPaymentFailureController', function ($scope, $state, UserService, PitchPaymentService) {

        $scope.errorMessage = $state.params.error_message;
        $scope.pitchFundingId = $state.params.pitch_funding_id;

        if ($scope.pitchFundingId) {
            try {
                PitchPaymentService.cancelPayFastPayment($scope.pitchFundingId).then((res) => {
                    console.log(res);
                });
            } catch (e) {
                console.log(e);
            }
        }

        $scope.viewProfile = function () {
            UserService.getUserDetails().then(res => {
                $state.transitionTo('user-profile', {user_id: res.user_id})
            });
        };

    });

