angular.module('uprise')

    .factory('PitchService', function ($http) {

        let service = {

            retrieveFeaturedPitches: function () {
                let req = {
                    method: 'GET',
                    url: '/api/pitch/featured_list',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 60000,
                };

                return $http(req);
            },

            retrievePitchInvestmentOptions: function (pitchId) {

                let req = {
                    method: 'GET',
                    url: `/api/pitch/investment_options/${pitchId}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 60000,
                };

                return $http(req);
            },

            fetchPitchCategories: function () {
                return $http.get('/api/pitch/fetch_categories')
            },

            retrievePitchInvestorsCount: function (pitchId) {

                let req = {
                    method: 'GET',
                    url: `/api/pitch/fetch_investors/${pitchId}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 60000,
                };

                return $http(req);
            }

        };

        return service;

    });
