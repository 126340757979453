angular.module('uprise')

    .controller('ContactController', function ($scope, ContactService) {
        $scope.contact = {};
        $scope.submitting_form = false;
        $scope.form_submitted = false;


        $scope.submitContactForm = function () {
            $scope.submitting_form = true;
            ContactService.submitContactForm($scope.contact).then(() => {
                $scope.submitting_form = false;
                $scope.form_submitted = true;
                $scope.contact = {};
            });
        }

    });