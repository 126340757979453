angular.module('uprise')
    .controller('PitchFundingPaymentController', function ($scope, $state, $window, PitchPaymentService, PitchFundingService, PitchSetupService, ExchangeService, UserService) {

        let pitchId = $state.params.pitch_id;
        let dataLayer = $window.dataLayer = $window.dataLayer || [];
        let userProfileDetails = {};

        $scope.loading_page = true;

        $scope.formData = {
            pitchInvestmentOptionId: 0,
            investmentPledged: 0
        };

        $scope.backToPitch = function () {
            $state.transitionTo('view-pitch-details', {pitch_id: pitchId});
        };

        $scope.selectStep = function ($event, i) {

            $event.preventDefault();

            switch (i) {

                case 1:
                    $scope.step = 1;
                    $state.transitionTo('pitch-funding.pledge', {pitch_id: pitchId});
                    break;

                case 2:

                    $scope.step = 2;
                    $state.transitionTo('pitch-funding.assessment', {pitch_id: pitchId});
                    break;

                case 3:

                    $scope.step = 3;
                    $state.transitionTo('pitch-funding.your_details', {pitch_id: pitchId});
                    break;

                case 4:

                    $scope.step = 4;
                    $state.transitionTo('pitch-funding.disclaimer', {pitch_id: pitchId});
                    break;

                case 6:
                    $scope.step = 6;
                    $state.transitionTo('pitch-funding-pledge-agreement', {pitch_id: pitchId});
                    break;
            }


        };

        $scope.initiateMyGatePayment = function () {

            dataLayer.push({
                'event': 'step_paymentoption',
                'pitchId': pitchId,
                'userId': userProfileDetails.id,
                'amt': $scope.formData.investmentPledged
            });

            PitchPaymentService.initiateMyGatePayment($scope.formData.investmentPledged, $scope.pitchFundingId).then(res => {
                let payload = res.data;

                $('#Mode').val(payload.Mode);
                $('#MerchantID').val(encodeURIComponent(payload.MerchantID));
                $('#ApplicationID').val(encodeURIComponent(payload.ApplicationID));
                $('#MerchantReference').val(payload.MerchantReference);
                $('#Amount').val(payload.Amount);
                $('#Currency').val(payload.Currency);
                $('#RedirectSuccessfulURL').val(payload.RedirectSuccessfulURL);
                $('#RedirectFailedURL').val(payload.RedirectFailedURL);
                $('#Variable1').val(payload.Variable1);
                $('#Qty1').val(payload.Qty1);
                $('#ItemRef1').val(payload.ItemRef1);
                $('#ItemDescr1').val(payload.ItemDescr1);
                $('#ItemAmount1').val(payload.ItemAmount1);
                $('#frmPost').attr('action', payload.MyGateURL);
                $('#frmPost').submit();
            })
        };

        $scope.initiatePayPalPayment = function () {
            ExchangeService.exchange_promise(parseFloat($scope.formData.investmentPledged)).then(amount => {

                dataLayer.push({
                    'event': 'step_paymentsuccess',
                    'pitchId': pitchId,
                    'userId': userProfileDetails.id,
                    'amt': $scope.formData.investmentPledged
                });

                PitchPaymentService.initiatePayPalPayment(parseFloat(amount).toFixed(2), $scope.pitchFundingId, pitchId).then(res => {
                    $window.location.href = res.data.paypal_url + res.data.payKey
                })
            });

        };

        $scope.initiatePayFastEFTPayment = function () {
            dataLayer.push({
                'event': 'step_paymentsuccess',
                'pitchId': pitchId,
                'userId': userProfileDetails.id,
                'amt': $scope.formData.investmentPledged
            });

            PitchPaymentService.initiatePayFastEFTPayment($scope.formData.investmentPledged, $scope.pitchFundingId, pitchId).then(res => {
                let payload = res.data;

                $('#payfast_merchant_id').val(payload.merchant_id);
                $('#payfast_merchant_key').val(payload.merchant_key);
                $('#payfast_return_url').val(payload.return_url);
                $('#payfast_cancel_url').val(payload.cancel_url);
                $('#payfast_notify_url').val(payload.notify_url);
                $('#payfast_name_first').val(payload.name_first);
                $('#payfast_name_last').val(payload.name_last);
                $('#payfast_email_address').val(payload.email_address);
                $('#payfast_amount').val(payload.amount);
                $('#payfast_item_name').val(payload.item_name);
                $('#payfast_item_description').val(payload.item_name);
                $('#payfast_m_payment_id').val(payload.m_payment_id);
                $('#payfast_payment_method').val(payload.payment_method);
                $('#payfast_signature').val(payload.signature);
                $('#frmPayFastPost').attr('action', payload.url);
                $('#frmPayFastPost').submit();
            })
        };

        $scope.initiatePayFastCreditCardPayment = function () {
            dataLayer.push({
                'event': 'step_paymentsuccess',
                'pitchId': pitchId,
                'userId': userProfileDetails.id,
                'amt': $scope.formData.investmentPledged
            });

            PitchPaymentService.initiatePayFastCreditCardPayment($scope.formData.investmentPledged, $scope.pitchFundingId, pitchId).then(res => {
                let payload = res.data;

                $('#payfast_merchant_id').val(payload.merchant_id);
                $('#payfast_merchant_key').val(payload.merchant_key);
                $('#payfast_return_url').val(payload.return_url);
                $('#payfast_cancel_url').val(payload.cancel_url);
                $('#payfast_notify_url').val(payload.notify_url);
                $('#payfast_name_first').val(payload.name_first);
                $('#payfast_name_last').val(payload.name_last);
                $('#payfast_email_address').val(payload.email_address);
                $('#payfast_amount').val(payload.amount);
                $('#payfast_item_name').val(payload.item_name);
                $('#payfast_item_description').val(payload.item_name);
                $('#payfast_m_payment_id').val(payload.m_payment_id);
                $('#payfast_payment_method').val(payload.payment_method);
                $('#payfast_signature').val(payload.signature);
                $('#frmPayFastPost').attr('action', payload.url);
                $('#frmPayFastPost').submit();
            })
        };

        $scope.initiateEFTPayment = function () {
            dataLayer.push({
                'event': 'step_paymentsuccess',
                'pitchId': pitchId,
                'userId': userProfileDetails.id,
                'amt': $scope.formData.investmentPledged
            });

            PitchPaymentService.initiateEFTPayment($scope.formData.investmentPledged, $scope.pitchFundingId, pitchId).then(res => {
                $state.transitionTo('pitch-funding-eft-details', {pitch_funding_id: $scope.pitchFundingId});
            });
        };

        const init = function () {

            dataLayer.push({
                'event': 'step_paymentoption',
                'pitchId': pitchId,
                'userId': userProfileDetails.id,
                'amt': $scope.formData.investmentPledged
            });

            UserService.checkUserSignedIn().then(res => {
                if (res) {
                    //yes, signed in, retrieve their profile details
                    UserService.fetchUserProfile(res.user_id).then(res => {
                        userProfileDetails = res.data.user_info;
                        PitchFundingService.retrievePitchIncompleteFunding(pitchId).then(res => {

                            $scope.pitchFundingId = res.data.id;
                            $scope.formData.pitchInvestmentOptionId = res.data.pitch_investment_option.id;
                            $scope.formData.investmentPledged = res.data.investment_pledged;
                            $scope.selectedInvestmentOption = res.data.pitch_investment_option;

                            //step 3: fetch the pitch details
                            PitchSetupService.fetchPitchDetails(pitchId).then(res => {
                                $scope.loading_page = false;
                                $scope.pitchDetails = res.data;
                                let end_date = moment($scope.pitchDetails.finish_date);
                                let todays_date = moment();
                                $scope.pitchDetails.days_left = end_date.diff(todays_date, 'days');
                            });

                        });
                    });
                }
            });
        };

        init();

    });

