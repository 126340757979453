angular.module('uprise')
    .controller('pitchFundingAgreement', function ($scope, $state, $sce, $window, $timeout, PitchFundingService, PitchSetupService, UserService) {

        let pitchId = $state.params.pitch_id;
        let dataLayer = $window.dataLayer = $window.dataLayer || [];
        let userProfileDetails = {};
        let pitchInvestmentPledged = '';

        $scope.quicklysign = {};
        $scope.fetching_url = true;
        $scope.formData = {};
        $scope.investor = {};


        $scope.selectStep = function ($event, i) {

            $event.preventDefault();

            switch (i) {

                case 1:
                    $scope.step = 1;
                    $state.transitionTo('pitch-funding.pledge', {pitch_id: pitchId});
                    break;

                case 2:

                    $scope.step = 2;
                    $state.transitionTo('pitch-funding.assessment', {pitch_id: pitchId});
                    break;

                case 3:

                    $scope.step = 3;
                    $state.transitionTo('pitch-funding.your_details', {pitch_id: pitchId});
                    break;

                case 4:

                    $scope.step = 4;
                    $state.transitionTo('pitch-funding.disclaimer', {pitch_id: pitchId});
                    break;

                case 5:
                    $scope.step = 5;
                    $state.transitionTo('pitch-funding-pledge-payment', {pitch_id: pitchId});
                    break;
            }


        };

        $scope.continueToPayment = function () {
            dataLayer.push({
                'event': 'step_agreement',
                'pitchId': pitchId,
                'userId': userProfileDetails.id,
                'amt': $scope.formData.investmentPledged
            });

            $state.transitionTo('pitch-funding-pledge-payment', {pitch_id: pitchId})
        };

        const init = function () {
            UserService.checkUserSignedIn().then(res => {
                //yes, signed in, retrieve their profile details
                if (res) {
                    UserService.fetchUserProfile(res.user_id).then(res => {
                        userProfileDetails = res.data.user_info;
                    });
                }
            });

            PitchFundingService.retrievePitchIncompleteFunding(pitchId).then(res => {

                $scope.pitchFundingId = res.data.id;
                $scope.formData.pitchInvestmentOptionId = res.data.pitch_investment_option.id;
                $scope.formData.investmentPledged = res.data.investment_pledged;
                $scope.selectedInvestmentOption = res.data.pitch_investment_option;
                $scope.documentSigned = res.data.document_signed;
                $scope.pitchFundingStatus = res.data.status.code;

                if ($scope.documentSigned === true) {
                    $scope.fetching_url = false
                }

                //step 3: fetch the pitch details
                PitchSetupService.fetchPitchDetails(pitchId).then(res => {
                    $scope.pitchDetails = res.data;
                    let end_date = moment($scope.pitchDetails.finish_date);
                    let todays_date = moment();
                    $scope.pitchDetails.days_left = end_date.diff(todays_date, 'days');
                });

                PitchFundingService.generateDocument($scope.formData.investmentPledged, $scope.pitchFundingId).then(res => {
                    $scope.quicklysign.url = res.data.quickly_sign_document_url;
                    $scope.quicklysign.document_signed = res.data.document_signed;

                    if (!$scope.documentSigned) {
                        QuicklySign.init("ahFzfnRoZW1hc3NpdmUtbGl2ZXIYCxILQXBwbGljYXRpb24YgICAhuTFigkM");
                        QuicklySign.open({
                            url: $scope.quicklysign.url,
                            container_id: "container",
                            post_sign_url: "https://www.uprise.africa/payment/" + pitchId,
                            message_listener: function (event_data) {
                                if (event_data.event === 'signed') {
                                    $scope.documentSigned = true;
                                    $scope.$apply();
                                }
                            }
                        });

                        $timeout(function () {
                            $scope.fetching_url = false;
                        }, 3000);
                    }
                });
            });

        };

        init();

    });

