'use strict';

let homeRoute = '/';
let signupRoute = '/signup';
let loginRoute = '/login';
let investorTypeRoute = '/investor_type';
let welcomeRoute = '/welcome';
let raiseCapitalRoute = '/raise-capital';
let createPitchRoute = '/create-pitch';
let createPitchDetailsRoute = '/details/:pitch_id';
let createPitchDoneRoute = '/done';
let pitchDetails = '/view_pitch/:pitch_id?tmtData';
let pitchFunding = '/pitch/pledge';
let pitchFundingPledge = '/amount/:pitch_id';
let pitchFundingAssessment = '/assessment/:pitch_id';
let pitchFundingYourDetails = '/your_details/:pitch_id';
let pitchFundingEFTDetails = '/eft_details/:pitch_funding_id';
let pitchFundingDisclaimer = '/disclaimer/:pitch_id';
let pitchFundingAgreement = '/agreement/:pitch_id';
let pitchFundingPayment = '/payment/:pitch_id';
let pitchFundingPaymentSuccess = '/payment_success?pitch';
let pitchFundingPaymentFailure = '/payment_failure/:error_message/:pitch_funding_id';
let userProfile = '/profile/:user_id';
let riskWarningRoute = '/risk-warning';
let termsAndConditionsRoute = '/terms-and-conditions';
let privacyPolicyRoute = '/privacy-policy';
let discoverRoute = '/discover/:query_string';
let contactRoute = '/contact';
let emailVerificationRoute = '/user/:user_id/verify-email/:token';


angular.module('uprise', [
    'ui.router',
    '720kb.socialshare'
])

    .config(['$locationProvider', function ($locationProvider) {
        $locationProvider.html5Mode(true);
    }])

    .config(function ($httpProvider) {
        $httpProvider.interceptors.push('responseObserver');
    })

    .config(function ($stateProvider, $urlRouterProvider) {

        $stateProvider

            .state('home', {
                url: homeRoute,
                templateUrl: '/static/partials/home.html',
                controller: 'HomeController'
            })

            .state('signup', {
                url: signupRoute,
                templateUrl: '/static/partials/signup.html',
                controller: 'SignUpUserController'
            })

            .state('signin', {
                url: loginRoute,
                templateUrl: '/static/partials/signin.html',
                controller: 'SignInUserController',
                params: {
                    previousState: null
                }
            })

            .state('investor_type', {
                url: investorTypeRoute,
                templateUrl: '/static/partials/user_investor_type.html',
                controller: 'UserInvestorTypeController'
            })

            .state('welcome', {
                url: welcomeRoute,
                templateUrl: '/static/partials/user_welcome.html',
                controller: 'UserWelcomeController'
            })

            .state('raise-capital', {
                url: raiseCapitalRoute,
                templateUrl: '/static/partials/user_welcome.html',
                controller: 'UserRaiseCapitalController'
            })


            .state('view-pitch-details', {
                url: pitchDetails,
                templateUrl: '/static/partials/pitch/view_pitch_details.html',
                controller: 'PitchDetailsController'
            })

            .state('pitch-funding', {
                url: pitchFunding,
                templateUrl: '/static/partials/pitch_funding/pledge.html',
                controller: 'PitchFundingPledgeController',
                abstract: true
            })

            //pledge investment in a pitch

            .state('pitch-funding.pledge', {
                url: pitchFundingPledge,
                templateUrl: '/static/partials/pitch_funding/includes/pledge_step_1_choose_amount.html'
            })

            .state('pitch-funding.assessment', {
                url: pitchFundingAssessment,
                templateUrl: '/static/partials/pitch_funding/includes/pledge_step_2_assessment.html'
            })

            .state('pitch-funding.your_details', {
                url: pitchFundingYourDetails,
                templateUrl: '/static/partials/pitch_funding/includes/pledge_step_3_your_details.html'
            })

            .state('pitch-funding.disclaimer', {
                url: pitchFundingDisclaimer,
                templateUrl: '/static/partials/pitch_funding/includes/pledge_step_4_disclaimer.html'
            })

            .state('pitch-funding-pledge-agreement', {
                url: pitchFundingAgreement,
                templateUrl: '/static/partials/pitch_funding/pledge_document_signing.html',
                controller: 'pitchFundingAgreement'
            })

            .state('pitch-funding-pledge-payment', {
                url: pitchFundingPayment,
                templateUrl: '/static/partials/pitch_funding/pledge_payment.html',
                controller: 'PitchFundingPaymentController'
            })

            .state('pitch-funding-eft', {
                url: pitchFundingPayment,
                templateUrl: '/static/partials/pitch_funding/pledge_payment.html',
                controller: 'PitchFundingPaymentController'
            })

            .state('pitch-funding-eft-details', {
                url: pitchFundingEFTDetails,
                templateUrl: '/static/partials/pitch_funding/payment_eft_details.html',
                controller: 'PitchFundingEFTDetailsControllerController'
            })

            .state('payment-success', {
                url: pitchFundingPaymentSuccess,
                templateUrl: '/static/partials/pitch_funding/payment_success.html',
                controller: 'PitchFundingPaymentSuccessController'
            })

            .state('payment-failure', {
                url: pitchFundingPaymentFailure,
                templateUrl: '/static/partials/pitch_funding/payment_failure.html',
                controller: 'PitchFundingPaymentFailureController'
            })


            // create a pitch

            .state('create-pitch', {
                url: createPitchRoute,
                templateUrl: '/static/partials/pitch/create_pitch.html',
                controller: 'PitchSetupController',
                abstract: true
            })

            .state('create-pitch.details', {
                url: createPitchDetailsRoute,
                templateUrl: '/static/partials/pitch/includes/create_pitch_details.html',
            })

            .state('create-pitch.thank-you', {
                url: createPitchDoneRoute,
                templateUrl: '/static/partials/pitch/includes/create_pitch_thank_you.html',
            })

            .state('user-profile', {
                url: userProfile,
                templateUrl: '/static/partials/profile/profile.html',
                controller: 'UserProfileController',
            })

            .state('risk-warning', {
                url: riskWarningRoute,
                templateUrl: '/static/partials/risk_warning/risk_warning.html',
            })

            .state('terms-and-conditions', {
                url: termsAndConditionsRoute,
                templateUrl: '/static/partials/terms_and_conditions/terms_and_conditions.html',
            })

            .state('privacy-policy', {
                url: privacyPolicyRoute,
                templateUrl: '/static/partials/privacy_policy/privacy_policy.html',
            })

            // discover pitches

            .state('discover', {
                url: discoverRoute,
                templateUrl: '/static/partials/discover/discover.html',
                controller: 'DiscoverController',
            })

            // contact page

            .state('contact', {
                url: contactRoute,
                templateUrl: '/static/partials/contact/contact.html',
                controller: 'ContactController',
            })

            // email verification

            .state('email-verification', {
                url: emailVerificationRoute,
                templateUrl: '/static/partials/email_verification.html',
                controller: 'EmailVerificationController',
            });

        // catch all routes
        $urlRouterProvider.otherwise(homeRoute);

    })

    .run(function ($rootScope, $anchorScroll, $window, UserService) {
        let dataLayer = $window.dataLayer = $window.dataLayer || [];

        $rootScope.$on('$stateChangeSuccess', function () {
            $anchorScroll('top');
            $anchorScroll.yOffset = 50;

            UserService.checkUserSignedIn().then(res => {
                if (res) {
                    console.log('auth - contentChanged');
                    dataLayer.push({
                        'event': 'contentChanged',
                        'urlpath': window.location.pathname,
                        'userId': res.user_id
                    });
                } else {
                    console.log('no auth - contentChanged');
                    dataLayer.push({
                        'event': 'contentChanged',
                        'urlpath': window.location.pathname,
                    });
                }
            });
        });

        UserService.checkUserSignedIn().then(res => {
            if (res) {
                $rootScope.$broadcast('handleLoginStateChangeBroadcast', res);
            }
        });
    });
