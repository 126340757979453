angular.module('uprise')

    .controller('SignInUserController', function ($scope, $rootScope, $timeout, $state, UserService) {

        $scope.email_address = "";
        $scope.no_user_found = false;
        $scope.forgot_password = false;
        $scope.no_email_address = false;
        $scope.submitting_form = false;
        $scope.incorrect_details = false;
        $scope.redirect_user_after_login = false;

        if ($state.params.previousState) {
            $scope.redirect_user_after_login = true;
        }

        $scope.signIn = function ($event) {

            let payload = {
                email_address: $scope.email_address,
                password: $event.currentTarget.elements['password'].value // so we don't store the password on the scope

            };

            $scope.submitting_form = true;

            UserService.userSignin(payload).then((response) => {
                $rootScope.$broadcast('handleLoginStateChangeBroadcast', response.data);

                if (localStorage.getItem('tmtData')) {
                    UserService.saveAdmarulaToken(localStorage.getItem('tmtData')).then(() => {
                        localStorage.removeItem('tmtData');
                    });
                }

                let nextState = "home";
                if ($state.params && $state.params.previousState) {
                    nextState = $state.params.previousState.name;
                }

                if ($state.params
                    && $state.params.previousState
                    && ($state.params.previousState.param_name && !!$state.params.previousState.param_id)) {

                    let payload = {};
                    payload[$state.params.previousState.param_name] = $state.params.previousState.param_id;
                    $state.transitionTo(nextState, payload);

                } else {

                    $state.transitionTo(nextState);

                }

                $scope.submitting_form = false;

            }, (err) => {
                console.log(err);
                if (err.status === 401) {
                    $scope.incorrect_details = true;
                    $timeout(function () {
                        $scope.incorrect_details = false;
                    }, 6000)
                }
                $scope.submitting_form = false;
            });

        };

        $scope.signInWithFacebook = function () {
            $scope.submitting_form = true;

            FB.login(function (response) {
                let accessToken = response.authResponse.accessToken;
                if (response.status === 'connected') {
                    FB.api('/me', function () {
                        UserService.facebookUserSignIn(accessToken).then(res => {
                            $rootScope.$broadcast('handleLoginStateChangeBroadcast', res.data);
                            UserService.updateUserDetails(res.data);

                            if (localStorage.getItem('tmtData')) {
                                UserService.saveAdmarulaToken(localStorage.getItem('tmtData')).then(() => {
                                    localStorage.removeItem('tmtData');
                                });
                            }

                            let nextState = "home";
                            if ($state.params && $state.params.previousState) {
                                nextState = $state.params.previousState.name;
                            }

                            if ($state.params
                                && $state.params.previousState
                                && ($state.params.previousState.param_name && !!$state.params.previousState.param_id)) {

                                let payload = {};
                                payload[$state.params.previousState.param_name] = $state.params.previousState.param_id;
                                $state.transitionTo(nextState, payload);

                            } else {

                                $state.transitionTo(nextState);

                            }

                            $scope.submitting_form = false;

                        }).catch(function () {
                            $scope.no_user_found = true;
                            $scope.submitting_form = false;
                        })
                    });
                }
                else {
                    console.log('[ERROR] User cancelled login or did not fully authorise (Facebook).')
                }
            }, {scope: 'email', return_scopes: true});
        };

        $scope.forgotPassword = function () {
            if (!$scope.email_address) {
                $scope.no_email_address = true;
                return;
            }
            UserService.forgotPassword($scope.email_address).then(() => {
                $scope.forgot_password = true;
                $scope.no_email_address = false;
            });
        };

        // Set the default value of inputType
        $scope.inputType = "password";

        // Hide & show password function
        $scope.hideShowPassword = function () {
            if ($scope.inputType == "password")
                $scope.inputType = "text";
            else
                $scope.inputType = "password";
        };

        $scope.options = {
            'width': 240,
            'height': 50,
            'longtitle': true,
            'onsuccess': function (response) {
                UserService.GoogleUserSignIn(response).then(res => {
                    $rootScope.$broadcast('handleLoginStateChangeBroadcast', res.data);

                    UserService.updateUserDetails(res.data);

                    if (localStorage.getItem('tmtData')) {
                        UserService.saveAdmarulaToken(localStorage.getItem('tmtData')).then(() => {
                            localStorage.removeItem('tmtData');
                        });
                    }

                    let nextState = "home";
                    if ($state.params && $state.params.previousState) {
                        nextState = $state.params.previousState.name;
                    }

                    if ($state.params
                        && $state.params.previousState
                        && ($state.params.previousState.param_name && !!$state.params.previousState.param_id)) {

                        let payload = {};
                        payload[$state.params.previousState.param_name] = $state.params.previousState.param_id;
                        $state.transitionTo(nextState, payload);

                    } else {

                        $state.transitionTo(nextState);

                    }

                    $scope.submitting_form = false;

                }).catch(function () {
                    $scope.no_user_found = true;
                    $scope.submitting_form = false;
                })
            }
        };

    });