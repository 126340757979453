angular.module('uprise')
    .controller('HomeController', function ($scope, $state, $sce, $window, PitchService, UserService, PitchSetupService) {

        $scope.newsletter = {};
        $scope.fetched_pitches = false;

        let dataLayer = $window.dataLayer = $window.dataLayer || [];

        $scope.trustHtml = function (html) {
            return $sce.trustAsHtml(html);
        };

        function retrieveFeaturedPitches() {

            PitchService.retrieveFeaturedPitches().then(res => {

                $scope.featuredPitches = res.data.pitches;

                angular.forEach($scope.featuredPitches, function (pitch) {
                    let end_date = moment(pitch.finish_date);
                    let todays_date = moment();
                    pitch.days_left = end_date.diff(todays_date, 'days');
                    if (pitch.short_blurb.length > 150) {
                        pitch.short_blurb = pitch.short_blurb.slice(0, 150);
                        pitch.short_blurb += ` <span>...read more</span>`;
                    }
                    pitch.short_blurb = $scope.trustHtml(pitch.short_blurb);
                });

                $scope.fetched_pitches = true;
            });

        }

        $(document).ready(function () {
            $('.modal').modal();
        });

        retrieveFeaturedPitches();

        $scope.viewPitchDetails = function (pitchId) {
            $state.transitionTo('view-pitch-details', {pitch_id: pitchId});

        };

        $scope.raiseCapital = function () {

            //is the user already signed in?
            UserService.checkUserSignedIn().then(res => {

                if (res) {
                    //yes, signed in, retrieve their profile details
                    UserService.fetchUserProfile(res.user_id).then(res => {
                        //have they indicated their investor type?
                        if (res.data.user_info.investor_type) {

                            //yes, create an empty pitch and send them to setup page
                            PitchSetupService.createPitch().then(res => {
                                $state.transitionTo('create-pitch.details', {pitch_id: res.data.id});
                            });

                        } else {
                            //request their investor type
                            $state.transitionTo('investor_type');

                        }

                    });
                }

            }, reject => {

                //not signed in, take them to signin
                $state.transitionTo('signin', {previousState: {name: 'raise-capital'}});

            });

        };

        $scope.signUpToNewsletter = function () {
            UserService.newsletterSignUp($scope.newsletter.email_address).then(() => {

                dataLayer.push({'event': 'newsletterSubscribed'});

                $scope.newsletter.email_address = '';

                $scope.SubscriptionResultMessage = "You have successfully subscribed to our newsletter, thank you!";
                $('#modalSuccessfulSubscription').modal('open');

            }, err => {
                console.log(err);
                $scope.SubscriptionResultMessage = "Unfortunately there was an error attempting to subscribe to our newsletter, please try again.";
                $('#modalSuccessfulSubscription').modal('open');
            });
        };

    });