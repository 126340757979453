angular.module('uprise')

    .factory('PitchSetupService', function ($http, $q) {

        let service = {

            createPitch: function () {
                let req = {
                    method: 'POST',
                    url: '/api/pitch_crud/',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 60000,
                };

                return $http(req);
            },

            editPitch: function (pitchId, details) {
                let req = {
                    method: 'PUT',
                    url: `/api/pitch_crud/${pitchId}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 60000,
                    data: {
                        details: details
                    }
                };

                return $http(req);
            },

            fetchPitchCreationDetails: function (pitch_id) {
                return $http.get('/api/pitch/pitch_creation/' + pitch_id)
            },

            fetchPitchDetails: function (pitch_id) {
                return $http.get('/api/pitch_crud/' + pitch_id)
            },

            fetchPitchDocuments: function (pitch_id) {
                return $http.get('/api/pitch/fetch_documents/' + pitch_id)
            },

            deletePitch: function (pitch_id) {
                return $http.get('/api/pitch/delete/' + pitch_id)
            }

        };

        return service;

    });
