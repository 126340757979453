angular.module('uprise')
    .controller('PitchFundingEFTDetailsControllerController', function ($scope, $state, $window, PitchFundingService) {

        function init() {

            const fundId = $state.params.pitch_funding_id;
            PitchFundingService.retrievePitchFunding(fundId).then(res => {
                $scope.fund = res.data;
            });

        }

        $scope.discover = function () {
            $state.transitionTo('discover');
        };

        init();


    });
