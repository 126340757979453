angular.module('uprise')

    .controller('EmailVerificationController', function ($scope, $window, $timeout, UserService, $state) {

        $scope.investorType = "";

        let user_id = $state.params.user_id;
        let token = $state.params.token;

        let init = function () {
            UserService.verifyToken(user_id, token).then(() => {
                UserService.redirectUser(user_id).then(res => {
                    const redirect_url = res.data.redirect_url;
                    $timeout(function () {
                        $window.location.href = redirect_url;
                    }, 3000);
                })
            });
        };

        init();

    });