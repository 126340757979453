angular.module('uprise')

    .controller('UserProfileController', function ($scope, $timeout, $location, UserService, PitchSetupService, $state) {

        $scope.editProfile = false;
        $scope.userProfileLoaded = false;
        $scope.pitchToDelete = {};
        $scope.change_password_submitted = false;
        $scope.loading_page = true;
        $scope.email_verification_sent = false;

        let user_id = $state.params.user_id;

        let init = function () {
            $('.modal').modal();

            UserService.fetchUserProfile(user_id).then(res => {
                $scope.userPitches = res.data.pitches;
                $scope.userProfileDetails = res.data.user_info;
                $scope.userProfileDetails.investor_type_clean = '';

                // if (!$scope.userProfileDetails.investor_type) {
                //     $state.transitionTo('investor_type');
                //     return
                // }
                if ($scope.userProfileDetails.investor_type) {
                    $scope.userProfileDetails.investor_type_clean = $scope.userProfileDetails.investor_type.toLowerCase().replace('_', ' ');
                    $scope.userProfileDetails.investor_type_clean = $scope.userProfileDetails.investor_type_clean.substring(0, 1).toUpperCase() + $scope.userProfileDetails.investor_type_clean.substring(1);
                    $scope.userInvestments = res.data.investments;
                }

                $scope.loading_page = false;
            })
        };

        init();

        $scope.deletePitchModal = function (pitch) {
            $scope.pitchToDelete = pitch;
            $('#deletePitch').modal('open');
        };

        $scope.deletePitch = function () {
            $('#deletePitch').modal('close');
            PitchSetupService.deletePitch($scope.pitchToDelete.id).then(() => {
                init();
            });
        };

        $scope.createPitch = function () {
            PitchSetupService.createPitch().then(res => {
                $state.transitionTo('create-pitch.details', {pitch_id: res.data.id});
            })
        };

        $scope.saveProfile = function () {
            UserService.editProfile($scope.userProfileDetails.email_address, $scope.userProfileDetails.investor_type).then(res => {
                $timeout(function () {
                    $scope.editProfile = false;
                    $scope.userProfileDetails = res.data.user;
                    $scope.userProfileDetails.investor_type_clean = $scope.userProfileDetails.investor_type.toLowerCase().replace('_', ' ');
                    $scope.userProfileDetails.investor_type_clean = $scope.userProfileDetails.investor_type_clean.substring(0, 1).toUpperCase() + $scope.userProfileDetails.investor_type_clean.substring(1);
                }, 0);
            });
        };

        $scope.verifyUserProfile = function () {
            UserService.verifyUserProfile($location.path()).then(() => {
                $timeout(function () {
                    $scope.email_verification_sent = true;
                }, 0);
            });
        };

        $scope.resumePitchFunding = function (pitch_id) {
            if (!$scope.userProfileDetails.investment_knowledge_assessment) {
                $state.transitionTo('pitch-funding.assessment', {pitch_id: pitch_id})
            }

            if ($scope.userProfileDetails.investment_knowledge_assessment && !$scope.userProfileDetails.proof_of_address_url && !$scope.userProfileDetails.id_document_url && !$scope.userProfileDetails.suburb) {
                $state.transitionTo('pitch-funding.your_details', {pitch_id: pitch_id})
            }

            if ($scope.userProfileDetails.proof_of_address_url && $scope.userProfileDetails.id_document_url && $scope.userProfileDetails.suburb) {
                $state.transitionTo('pitch-funding.disclaimer', {pitch_id: pitch_id})
            }

            if ($scope.userProfileDetails.proof_of_address_url && $scope.userProfileDetails.suburb) {
                $state.transitionTo('pitch-funding.disclaimer', {pitch_id: pitch_id})
            }

        };

        $scope.navigateToDocument = function (pitch_id) {
            $state.transitionTo('pitch-funding-pledge-agreement', {pitch_id: pitch_id})
        };

        $scope.changePassword = function () {
            $scope.change_password_submitted = true;
            UserService.changePassword($scope.userProfileDetails.email_address);
        };

    });