angular.module('uprise')
    .controller('PitchSetupController', function ($scope, $state, PitchService, PitchSetupService, UserService) {

        let pitch_id = $state.params.pitch_id;
        $scope.pitchDetails = {};
        $scope.pitchCategories = {};
        $scope.documents = [];
        $scope.uploadError = false;
        $scope.otherSelected = false;
        $scope.uploadErrorMessage = '';

        let init = function () {
            UserService.checkUserSignedIn().then(res => {
                if (res) {
                    PitchService.fetchPitchCategories().then((res) => {
                        $scope.pitchCategories = res.data.categories;
                        PitchSetupService.fetchPitchCreationDetails(pitch_id).then(res => {
                            $scope.pitchDetails = res.data;
                            if (!!$scope.pitchDetails.category) {
                                $scope.pitchDetails.category = $scope.pitchDetails.category.id;
                                $scope.handleSelectCategory();
                            }
                            PitchSetupService.fetchPitchDocuments(pitch_id).then(res => {
                                $scope.documents = res.data.documents
                            })
                        })
                    });
                } else {
                    $state.transitionTo('signin');
                }
            });
        };

        init();

        $scope.handleSelectCategory = function () {
            $scope.otherSelected = false;

            angular.forEach($scope.pitchCategories, function (category) {
                if (category.name === 'Other') {
                    if ($scope.pitchDetails.category === category.id) {
                        return $scope.otherSelected = true;
                    }
                }
            });
        };

        $scope.docUploaded = function (obj, id, event) {

            $scope.uploadError = false;
            $scope.uploadErrorMessage = '';

            const item = event.target;
            const file = $(item)[0].files[0];
            const upload = new Upload(file);

            // TODO: check size or type here with upload.getSize() and upload.getType()
            console.log(upload.getSize());
            console.log(upload.getType());

            jQuery(`#progress_parent_${id}`).toggle();

            upload.doUpload().then((docUrl) => {
                jQuery(`#progress_parent_${id}`).toggle();
                $scope.documents.push({
                    "document_name": upload.getName(),
                    "document_url": docUrl
                });
                $scope.$apply();
            }, err => {
                jQuery(`#progress_parent_${id}`).toggle();
                console.log(err);
                $scope.uploadError = true;
                $scope.uploadErrorMessage = err.responseText;
                $scope.$apply();
            });
        };

        $scope.submitForm = function (submission) {

            let details = {
                name: $scope.pitchDetails.name,
                contact_number: $scope.pitchDetails.contact_number,
                website: $scope.pitchDetails.website,
                category: $scope.pitchDetails.category,
                location: $scope.pitchDetails.location,
                funding_goal_capital: $scope.pitchDetails.funding_goal_capital,
                duration: $scope.pitchDetails.duration,
                investor_docs: $scope.documents,

                other_category_name: $scope.pitchDetails.other_category_name,

                high_level_pitch: $scope.pitchDetails.high_level_pitch,
                unique_value_proposition: $scope.pitchDetails.unique_value_proposition,
                solving_problem: $scope.pitchDetails.solving_problem,
                how_will_it_change_lives: $scope.pitchDetails.how_will_it_change_lives,
                estimated_market_size: $scope.pitchDetails.estimated_market_size,
                your_solution: $scope.pitchDetails.your_solution,
                product_features: $scope.pitchDetails.product_features,
                own_ip: $scope.pitchDetails.own_ip,

                main_competitors: $scope.pitchDetails.main_competitors,
                global_competitors: $scope.pitchDetails.global_competitors,
                how_are_you_different: $scope.pitchDetails.how_are_you_different,

                do_you_have_customers: $scope.pitchDetails.do_you_have_customers,
                do_you_have_contracts: $scope.pitchDetails.do_you_have_contracts,
                do_you_have_pilots: $scope.pitchDetails.do_you_have_pilots,

                team_size: $scope.pitchDetails.team_size,
                how_many_team_members_currently_working: $scope.pitchDetails.how_many_team_members_currently_working,
                team_details: $scope.pitchDetails.team_details,

                generating_revenue: $scope.pitchDetails.generating_revenue,
                average_turnover: $scope.pitchDetails.average_turnover,
                operating_profit: $scope.pitchDetails.operating_profit,
                historical_financials: $scope.pitchDetails.historical_financials,
                fiancials_history: $scope.pitchDetails.fiancials_history,
                current_runway: $scope.pitchDetails.current_runway,

                how_do_you_make_money: $scope.pitchDetails.how_do_you_make_money,
                evaluation_model: $scope.pitchDetails.evaluation_model,
                estimated_business_value: $scope.pitchDetails.estimated_business_value,
                willing_to_undergo_evaluation: $scope.pitchDetails.willing_to_undergo_evaluation,
                received_prior_funding: $scope.pitchDetails.received_prior_funding,
                how_much_funding: $scope.pitchDetails.how_much_funding,
                do_you_have_lead_investors: $scope.pitchDetails.do_you_have_lead_investors,

                expecting_more_funding: $scope.pitchDetails.expecting_more_funding,
                more_funding_details: $scope.pitchDetails.more_funding_details,
                primary_need_for_capital: $scope.pitchDetails.primary_need_for_capital,
                how_will_you_invest: $scope.pitchDetails.how_will_you_invest,
                runway_for_investment: $scope.pitchDetails.runway_for_investment,

                potential_return: $scope.pitchDetails.potential_return,
                time_for_potential_return: $scope.pitchDetails.time_for_potential_return,
                return_on_investment: $scope.pitchDetails.return_on_investment,

                potential_risks: $scope.pitchDetails.potential_risks,

                how_is_equity_split: $scope.pitchDetails.how_is_equity_split,
                existing_shareholding_agreements: $scope.pitchDetails.existing_shareholding_agreements,
                existing_shareholder_agreement_with: $scope.pitchDetails.existing_shareholder_agreement_with,
                employment_share_pools: $scope.pitchDetails.employment_share_pools,
                existing_holdings_structure: $scope.pitchDetails.existing_holdings_structure,
                existing_holdings: $scope.pitchDetails.existing_holdings,

                submission: submission
            };

            PitchSetupService.editPitch(pitch_id, details).then(() => {
                $state.transitionTo('create-pitch.thank-you');
            });

        };

        $scope.viewProfile = function () {
            UserService.getUserDetails().then(res => {
                $state.transitionTo('user-profile', {user_id: res.user_id})
            });
        };

        $scope.deleteDocument = function ($event, index) {
            $event.preventDefault();
            if (!$scope.documents) {
                return;
            }
            $scope.documents.splice(index, 1);

        }

    });
