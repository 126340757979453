angular.module('uprise')
    .factory('PitchPaymentService', function ($http) {

        let service = {

            initiateMyGatePayment: function (pledged_amount, pitch_funding_id) {
                let req = {
                    method: 'POST',
                    url: '/api/mygate/initiate_payment',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'pledged_amount': pledged_amount,
                        'pitch_funding_id': pitch_funding_id
                    }
                }

                return $http(req);
            },

            initiatePayFastCreditCardPayment: function (pledged_amount, pitch_funding_id, pitch_id) {
                let req = {
                    method: 'POST',
                    url: '/api/payfast/initiate_credit_card_payment',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'pledged_amount': pledged_amount,
                        'pitch_funding_id': pitch_funding_id,
                        'pitch_id': pitch_id,
                    }
                };

                return $http(req);
            },

            initiatePayFastEFTPayment: function (pledged_amount, pitch_funding_id, pitch_id) {
                let req = {
                    method: 'POST',
                    url: '/api/payfast/initiate_eft_payment',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'pledged_amount': pledged_amount,
                        'pitch_funding_id': pitch_funding_id,
                        'pitch_id': pitch_id,
                    },
                };

                return $http(req);
            },

            cancelPayFastPayment: function (pitch_funding_id) {
                let req = {
                    method: 'POST',
                    url: `/api/payfast/transaction_cancelled/${pitch_funding_id}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {},
                };

                return $http(req);
            },

            initiateEFTPayment: function (pledged_amount, pitch_funding_id, pitch_id) {
                let req = {
                    method: 'POST',
                    url: '/api/eft/initiate_payment',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'pledged_amount': pledged_amount,
                        'pitch_funding_id': pitch_funding_id,
                        'pitch_id': pitch_id,
                    },
                };

                return $http(req);
            },

            initiatePayPalPayment: function (pledged_amount, pitch_funding_id, pitch_id) {
                let req = {
                    method: 'POST',
                    url: '/api/paypal/initiate_payment',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'pledged_amount': pledged_amount,
                        'pitch_funding_id': pitch_funding_id,
                        'pitch_id': pitch_id,
                    }
                };

                return $http(req);
            }

        };

        return service;

    });
