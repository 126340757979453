'use strict';

var homeRoute = '/';
var signupRoute = '/signup';
var loginRoute = '/login';
var investorTypeRoute = '/investor_type';
var welcomeRoute = '/welcome';
var raiseCapitalRoute = '/raise-capital';
var createPitchRoute = '/create-pitch';
var createPitchDetailsRoute = '/details/:pitch_id';
var createPitchDoneRoute = '/done';
var pitchDetails = '/view_pitch/:pitch_id?tmtData';
var pitchFunding = '/pitch/pledge';
var pitchFundingPledge = '/amount/:pitch_id';
var pitchFundingAssessment = '/assessment/:pitch_id';
var pitchFundingYourDetails = '/your_details/:pitch_id';
var pitchFundingEFTDetails = '/eft_details/:pitch_funding_id';
var pitchFundingDisclaimer = '/disclaimer/:pitch_id';
var pitchFundingAgreement = '/agreement/:pitch_id';
var pitchFundingPayment = '/payment/:pitch_id';
var pitchFundingPaymentSuccess = '/payment_success?pitch';
var pitchFundingPaymentFailure = '/payment_failure/:error_message/:pitch_funding_id';
var userProfile = '/profile/:user_id';
var riskWarningRoute = '/risk-warning';
var termsAndConditionsRoute = '/terms-and-conditions';
var privacyPolicyRoute = '/privacy-policy';
var discoverRoute = '/discover/:query_string';
var contactRoute = '/contact';
var emailVerificationRoute = '/user/:user_id/verify-email/:token';

angular.module('uprise', ['ui.router', '720kb.socialshare']).config(['$locationProvider', function ($locationProvider) {
    $locationProvider.html5Mode(true);
}]).config(function ($httpProvider) {
    $httpProvider.interceptors.push('responseObserver');
}).config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider.state('home', {
        url: homeRoute,
        templateUrl: '/static/partials/home.html',
        controller: 'HomeController'
    }).state('signup', {
        url: signupRoute,
        templateUrl: '/static/partials/signup.html',
        controller: 'SignUpUserController'
    }).state('signin', {
        url: loginRoute,
        templateUrl: '/static/partials/signin.html',
        controller: 'SignInUserController',
        params: {
            previousState: null
        }
    }).state('investor_type', {
        url: investorTypeRoute,
        templateUrl: '/static/partials/user_investor_type.html',
        controller: 'UserInvestorTypeController'
    }).state('welcome', {
        url: welcomeRoute,
        templateUrl: '/static/partials/user_welcome.html',
        controller: 'UserWelcomeController'
    }).state('raise-capital', {
        url: raiseCapitalRoute,
        templateUrl: '/static/partials/user_welcome.html',
        controller: 'UserRaiseCapitalController'
    }).state('view-pitch-details', {
        url: pitchDetails,
        templateUrl: '/static/partials/pitch/view_pitch_details.html',
        controller: 'PitchDetailsController'
    }).state('pitch-funding', {
        url: pitchFunding,
        templateUrl: '/static/partials/pitch_funding/pledge.html',
        controller: 'PitchFundingPledgeController',
        abstract: true
    })

    //pledge investment in a pitch

    .state('pitch-funding.pledge', {
        url: pitchFundingPledge,
        templateUrl: '/static/partials/pitch_funding/includes/pledge_step_1_choose_amount.html'
    }).state('pitch-funding.assessment', {
        url: pitchFundingAssessment,
        templateUrl: '/static/partials/pitch_funding/includes/pledge_step_2_assessment.html'
    }).state('pitch-funding.your_details', {
        url: pitchFundingYourDetails,
        templateUrl: '/static/partials/pitch_funding/includes/pledge_step_3_your_details.html'
    }).state('pitch-funding.disclaimer', {
        url: pitchFundingDisclaimer,
        templateUrl: '/static/partials/pitch_funding/includes/pledge_step_4_disclaimer.html'
    }).state('pitch-funding-pledge-agreement', {
        url: pitchFundingAgreement,
        templateUrl: '/static/partials/pitch_funding/pledge_document_signing.html',
        controller: 'pitchFundingAgreement'
    }).state('pitch-funding-pledge-payment', {
        url: pitchFundingPayment,
        templateUrl: '/static/partials/pitch_funding/pledge_payment.html',
        controller: 'PitchFundingPaymentController'
    }).state('pitch-funding-eft', {
        url: pitchFundingPayment,
        templateUrl: '/static/partials/pitch_funding/pledge_payment.html',
        controller: 'PitchFundingPaymentController'
    }).state('pitch-funding-eft-details', {
        url: pitchFundingEFTDetails,
        templateUrl: '/static/partials/pitch_funding/payment_eft_details.html',
        controller: 'PitchFundingEFTDetailsControllerController'
    }).state('payment-success', {
        url: pitchFundingPaymentSuccess,
        templateUrl: '/static/partials/pitch_funding/payment_success.html',
        controller: 'PitchFundingPaymentSuccessController'
    }).state('payment-failure', {
        url: pitchFundingPaymentFailure,
        templateUrl: '/static/partials/pitch_funding/payment_failure.html',
        controller: 'PitchFundingPaymentFailureController'
    })

    // create a pitch

    .state('create-pitch', {
        url: createPitchRoute,
        templateUrl: '/static/partials/pitch/create_pitch.html',
        controller: 'PitchSetupController',
        abstract: true
    }).state('create-pitch.details', {
        url: createPitchDetailsRoute,
        templateUrl: '/static/partials/pitch/includes/create_pitch_details.html'
    }).state('create-pitch.thank-you', {
        url: createPitchDoneRoute,
        templateUrl: '/static/partials/pitch/includes/create_pitch_thank_you.html'
    }).state('user-profile', {
        url: userProfile,
        templateUrl: '/static/partials/profile/profile.html',
        controller: 'UserProfileController'
    }).state('risk-warning', {
        url: riskWarningRoute,
        templateUrl: '/static/partials/risk_warning/risk_warning.html'
    }).state('terms-and-conditions', {
        url: termsAndConditionsRoute,
        templateUrl: '/static/partials/terms_and_conditions/terms_and_conditions.html'
    }).state('privacy-policy', {
        url: privacyPolicyRoute,
        templateUrl: '/static/partials/privacy_policy/privacy_policy.html'
    })

    // discover pitches

    .state('discover', {
        url: discoverRoute,
        templateUrl: '/static/partials/discover/discover.html',
        controller: 'DiscoverController'
    })

    // contact page

    .state('contact', {
        url: contactRoute,
        templateUrl: '/static/partials/contact/contact.html',
        controller: 'ContactController'
    })

    // email verification

    .state('email-verification', {
        url: emailVerificationRoute,
        templateUrl: '/static/partials/email_verification.html',
        controller: 'EmailVerificationController'
    });

    // catch all routes
    $urlRouterProvider.otherwise(homeRoute);
}).run(function ($rootScope, $anchorScroll, $window, UserService) {
    var dataLayer = $window.dataLayer = $window.dataLayer || [];

    $rootScope.$on('$stateChangeSuccess', function () {
        $anchorScroll('top');
        $anchorScroll.yOffset = 50;

        UserService.checkUserSignedIn().then(function (res) {
            if (res) {
                console.log('auth - contentChanged');
                dataLayer.push({
                    'event': 'contentChanged',
                    'urlpath': window.location.pathname,
                    'userId': res.user_id
                });
            } else {
                console.log('no auth - contentChanged');
                dataLayer.push({
                    'event': 'contentChanged',
                    'urlpath': window.location.pathname
                });
            }
        });
    });

    UserService.checkUserSignedIn().then(function (res) {
        if (res) {
            $rootScope.$broadcast('handleLoginStateChangeBroadcast', res);
        }
    });
});
'use strict';

angular.module('uprise').controller('BaseController', function ($scope, UserService) {

    var init = function init() {
        UserService.checkUserSignedIn();
    };

    $scope.$on('handleLoginStateChangeBroadcast', function () {
        UserService.checkUserSignedIn().then(function (res) {
            if (res) {
                $scope.user_details = res;
            }
        });
    });
});
"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

// A class for uploading files to the server via jQuery ajax, to prevent the need for form posts and page refreshes
// Currently python libraries for google cloud storage only accept files via form post, not json request, meaning that normal JSON requests via angular won't work

var Upload = function () {
    function Upload(file) {
        _classCallCheck(this, Upload);

        this.file = file;
    }

    _createClass(Upload, [{
        key: "getType",
        value: function getType() {
            if (!this.file) {
                return '';
            }
            return this.file.type;
        }
    }, {
        key: "getSize",
        value: function getSize() {
            if (!this.file) {
                return 0;
            }
            return this.file.size;
        }
    }, {
        key: "getName",
        value: function getName() {
            return this.file.name;
        }
    }, {
        key: "doUpload",
        value: function doUpload() {

            var formData = new FormData();
            formData.append("file", this.file);
            formData.append("upload_file", true);

            return new Promise(function (resolve, reject) {

                $.ajax({
                    type: "POST",
                    url: "api/pitch/upload_doc",
                    success: function success(data) {
                        console.log(data);
                        resolve(data);
                    },
                    error: function error(_error) {
                        reject(_error);
                    },
                    async: true,
                    data: formData,
                    cache: false,
                    contentType: false,
                    processData: false,
                    timeout: 60000
                });
            });
        }
    }]);

    return Upload;
}();
'use strict';

angular.module('uprise').controller('ContactController', function ($scope, ContactService) {
    $scope.contact = {};
    $scope.submitting_form = false;
    $scope.form_submitted = false;

    $scope.submitContactForm = function () {
        $scope.submitting_form = true;
        ContactService.submitContactForm($scope.contact).then(function () {
            $scope.submitting_form = false;
            $scope.form_submitted = true;
            $scope.contact = {};
        });
    };
});
'use strict';

angular.module('uprise').factory('ContactService', function ($http) {

    var service = {

        submitContactForm: function submitContactForm(formData) {
            var req = {
                method: 'POST',
                url: '/api/user/contact_form',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'name': formData.name,
                    'email_address': formData.email_address,
                    'subject': formData.subject,
                    'message': formData.message
                },
                timeout: 60000
            };

            return $http(req);
        }

    };

    return service;
});
'use strict';

angular.module('uprise').factory('responseObserver', function responseObserver($q, $location) {
    return {
        'responseError': function responseError(errorResponse) {
            switch (errorResponse.status) {
                case 403:
                    $location.url('/');
                    break;
                case 500:
                    $location.url('/');
                    break;
                case 404:
                    $location.url('/');
                    break;
            }
            return $q.reject(errorResponse);
        }
    };
});
'use strict';

angular.module('uprise').controller('DiscoverController', function ($scope, $state, $sce, DiscoverService) {

    DiscoverService.query_string = $state.params.query_string;

    $scope.pitches = {};
    $scope.loading_page = true;

    $scope.trustHtml = function (html) {
        return $sce.trustAsHtml(html);
    };

    var init = function init() {
        DiscoverService.searchPitches().then(function (res) {
            $scope.loading_page = false;
            $scope.pitches = res.data.pitches;
            angular.forEach($scope.pitches, function (pitch) {
                var end_date = moment(pitch.finish_date);
                var todays_date = moment();
                pitch.days_left = end_date.diff(todays_date, 'days');
                if (pitch.short_blurb.length > 150) {
                    pitch.short_blurb = pitch.short_blurb.slice(0, 150);
                    pitch.short_blurb += ' <span>...read more</span>';
                }
                pitch.short_blurb = $scope.trustHtml(pitch.short_blurb);
            });
        });
    };

    init();

    $scope.viewPitchDetails = function (pitchId) {
        $state.transitionTo('view-pitch-details', { pitch_id: pitchId });
    };
});
'use strict';

angular.module('uprise').factory('DiscoverService', function ($http) {

    var service = {

        query_string: '',

        searchPitches: function searchPitches() {

            var instance = this;

            var req = {
                method: 'POST',
                url: '/api/pitch/search',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000,
                data: {
                    'query_string': instance.query_string
                }
            };

            return $http(req);
        }

    };

    return service;
});
'use strict';

angular.module('uprise').factory('ExchangeService', ['$http', '$q', function ($http, $q) {

    // This uses money.js to handle the actual conversions
    var exchangeService = {
        // we default to ZAR
        "country_code": "ZA",
        "country_name": "South Africa",
        "currency_symbol": "R",
        "currency_code": "ZAR",
        "currency_name": "Rand",
        "base": "ZAR",
        "rates": {
            "ZAR": 1.0
        },

        "refresh_exchange_info": function refresh_exchange_info() {
            var instance = this;

            fx.base = instance.base;
            fx.rates = instance.rates;
            fx.settings['to'] = instance.currency_code;

            return $http.get('/api/pitch_funding/fetch_exchange_rate/', { 'headers': { 'Content-Type': 'application/json' } }).then(function (res) {
                instance.country_code = res.data.country_code;
                instance.country_name = res.data.country_name;
                instance.currency_symbol = res.data.currency_symbol;
                instance.currency_code = res.data.currency_code;
                instance.currency_name = res.data.currency_name;
                instance.base = res.data.exchange_rates.base;
                instance.rates = res.data.exchange_rates.rates;

                fx.base = instance.base;
                fx.rates = instance.rates;
                fx.settings['to'] = instance.currency_code;
            }).catch(function (data) {
                console.log(data);
            });
        },

        "exchange": function exchange(amount) {
            var result = NaN;
            try {
                result = fx.convert(amount, { from: 'ZAR', to: 'USD' });
            } catch (err) {}

            return result;
        },

        "exchange_promise": function exchange_promise(amount) {
            var deferred = $q.defer();
            var instance = this;

            var exchanged = instance.exchange(amount);

            if (isNaN(exchanged)) {
                deferred.reject("Could not exchange values");
            } else {
                deferred.resolve(exchanged);
            }

            return deferred.promise;
        }
    };

    exchangeService.refresh_exchange_info();

    return exchangeService;
}]);
'use strict';

angular.module('uprise').controller('HomeController', function ($scope, $state, $sce, $window, PitchService, UserService, PitchSetupService) {

    $scope.newsletter = {};
    $scope.fetched_pitches = false;

    var dataLayer = $window.dataLayer = $window.dataLayer || [];

    $scope.trustHtml = function (html) {
        return $sce.trustAsHtml(html);
    };

    function retrieveFeaturedPitches() {

        PitchService.retrieveFeaturedPitches().then(function (res) {

            $scope.featuredPitches = res.data.pitches;

            angular.forEach($scope.featuredPitches, function (pitch) {
                var end_date = moment(pitch.finish_date);
                var todays_date = moment();
                pitch.days_left = end_date.diff(todays_date, 'days');
                if (pitch.short_blurb.length > 150) {
                    pitch.short_blurb = pitch.short_blurb.slice(0, 150);
                    pitch.short_blurb += ' <span>...read more</span>';
                }
                pitch.short_blurb = $scope.trustHtml(pitch.short_blurb);
            });

            $scope.fetched_pitches = true;
        });
    }

    $(document).ready(function () {
        $('.modal').modal();
    });

    retrieveFeaturedPitches();

    $scope.viewPitchDetails = function (pitchId) {
        $state.transitionTo('view-pitch-details', { pitch_id: pitchId });
    };

    $scope.raiseCapital = function () {

        //is the user already signed in?
        UserService.checkUserSignedIn().then(function (res) {

            if (res) {
                //yes, signed in, retrieve their profile details
                UserService.fetchUserProfile(res.user_id).then(function (res) {
                    //have they indicated their investor type?
                    if (res.data.user_info.investor_type) {

                        //yes, create an empty pitch and send them to setup page
                        PitchSetupService.createPitch().then(function (res) {
                            $state.transitionTo('create-pitch.details', { pitch_id: res.data.id });
                        });
                    } else {
                        //request their investor type
                        $state.transitionTo('investor_type');
                    }
                });
            }
        }, function (reject) {

            //not signed in, take them to signin
            $state.transitionTo('signin', { previousState: { name: 'raise-capital' } });
        });
    };

    $scope.signUpToNewsletter = function () {
        UserService.newsletterSignUp($scope.newsletter.email_address).then(function () {

            dataLayer.push({ 'event': 'newsletterSubscribed' });

            $scope.newsletter.email_address = '';

            $scope.SubscriptionResultMessage = "You have successfully subscribed to our newsletter, thank you!";
            $('#modalSuccessfulSubscription').modal('open');
        }, function (err) {
            console.log(err);
            $scope.SubscriptionResultMessage = "Unfortunately there was an error attempting to subscribe to our newsletter, please try again.";
            $('#modalSuccessfulSubscription').modal('open');
        });
    };
});
'use strict';

angular.module('uprise').controller('NavController', function ($scope, $state, UserService, PitchSetupService) {

    $scope.isAuthenticated = false;
    $scope.state = $state;
    $scope.search = {};

    $scope.$on('handleLoginStateChangeBroadcast', function (event, args) {
        $scope.isAuthenticated = true;
        $scope.userDetails = args;
    });

    $scope.navigateToProfile = function () {
        $state.transitionTo('user-profile', { user_id: $scope.userDetails.user_id });
    };

    $scope.signOut = function () {
        UserService.userSignout().then(function (res) {
            var auth2 = gapi.auth2.getAuthInstance();
            auth2.signOut().then(function () {
                auth2.disconnect();
                $scope.isAuthenticated = false;
                $scope.userDetails = null;
                $state.transitionTo('signin');
            });
        });
    };

    $scope.submitSearchQuery = function () {
        $state.transitionTo('discover', { query_string: $scope.search.query_string });
    };

    $scope.raiseCapital = function () {

        //is the user already signed in?
        UserService.checkUserSignedIn().then(function (res) {

            if (res) {
                //yes, signed in, retrieve their profile details
                UserService.fetchUserProfile(res.user_id).then(function (res) {

                    //have they indicated their investor type?
                    if (res.data.user_info.investor_type) {

                        //yes, create an empty pitch and send them to setup page
                        PitchSetupService.createPitch().then(function (res) {
                            $state.transitionTo('create-pitch.details', { pitch_id: res.data.id });
                        });
                    } else {

                        //request their investor type
                        $state.transitionTo('investor_type');
                    }
                });
            }
        }, function (reject) {

            //not signed in, take them to signin
            $state.transitionTo('signin', { previousState: { name: 'raise-capital' } });
        });
    };
});
'use strict';

angular.module('uprise').controller('NewUserController', function ($scope, $state) {

    $scope.invest = function () {
        $state.transitionTo('browse-projects');
    };
});
'use strict';

angular.module('uprise').controller('pitchFundingAgreement', function ($scope, $state, $sce, $window, $timeout, PitchFundingService, PitchSetupService, UserService) {

    var pitchId = $state.params.pitch_id;
    var dataLayer = $window.dataLayer = $window.dataLayer || [];
    var userProfileDetails = {};
    var pitchInvestmentPledged = '';

    $scope.quicklysign = {};
    $scope.fetching_url = true;
    $scope.formData = {};
    $scope.investor = {};

    $scope.selectStep = function ($event, i) {

        $event.preventDefault();

        switch (i) {

            case 1:
                $scope.step = 1;
                $state.transitionTo('pitch-funding.pledge', { pitch_id: pitchId });
                break;

            case 2:

                $scope.step = 2;
                $state.transitionTo('pitch-funding.assessment', { pitch_id: pitchId });
                break;

            case 3:

                $scope.step = 3;
                $state.transitionTo('pitch-funding.your_details', { pitch_id: pitchId });
                break;

            case 4:

                $scope.step = 4;
                $state.transitionTo('pitch-funding.disclaimer', { pitch_id: pitchId });
                break;

            case 5:
                $scope.step = 5;
                $state.transitionTo('pitch-funding-pledge-payment', { pitch_id: pitchId });
                break;
        }
    };

    $scope.continueToPayment = function () {
        dataLayer.push({
            'event': 'step_agreement',
            'pitchId': pitchId,
            'userId': userProfileDetails.id,
            'amt': $scope.formData.investmentPledged
        });

        $state.transitionTo('pitch-funding-pledge-payment', { pitch_id: pitchId });
    };

    var init = function init() {
        UserService.checkUserSignedIn().then(function (res) {
            //yes, signed in, retrieve their profile details
            if (res) {
                UserService.fetchUserProfile(res.user_id).then(function (res) {
                    userProfileDetails = res.data.user_info;
                });
            }
        });

        PitchFundingService.retrievePitchIncompleteFunding(pitchId).then(function (res) {

            $scope.pitchFundingId = res.data.id;
            $scope.formData.pitchInvestmentOptionId = res.data.pitch_investment_option.id;
            $scope.formData.investmentPledged = res.data.investment_pledged;
            $scope.selectedInvestmentOption = res.data.pitch_investment_option;
            $scope.documentSigned = res.data.document_signed;
            $scope.pitchFundingStatus = res.data.status.code;

            if ($scope.documentSigned === true) {
                $scope.fetching_url = false;
            }

            //step 3: fetch the pitch details
            PitchSetupService.fetchPitchDetails(pitchId).then(function (res) {
                $scope.pitchDetails = res.data;
                var end_date = moment($scope.pitchDetails.finish_date);
                var todays_date = moment();
                $scope.pitchDetails.days_left = end_date.diff(todays_date, 'days');
            });

            PitchFundingService.generateDocument($scope.formData.investmentPledged, $scope.pitchFundingId).then(function (res) {
                $scope.quicklysign.url = res.data.quickly_sign_document_url;
                $scope.quicklysign.document_signed = res.data.document_signed;

                if (!$scope.documentSigned) {
                    QuicklySign.init("ahFzfnRoZW1hc3NpdmUtbGl2ZXIYCxILQXBwbGljYXRpb24YgICAhuTFigkM");
                    QuicklySign.open({
                        url: $scope.quicklysign.url,
                        container_id: "container",
                        post_sign_url: "https://www.uprise.africa/payment/" + pitchId,
                        message_listener: function message_listener(event_data) {
                            if (event_data.event === 'signed') {
                                $scope.documentSigned = true;
                                $scope.$apply();
                            }
                        }
                    });

                    $timeout(function () {
                        $scope.fetching_url = false;
                    }, 3000);
                }
            });
        });
    };

    init();
});
'use strict';

angular.module('uprise').controller('PitchFundingEFTDetailsControllerController', function ($scope, $state, $window, PitchFundingService) {

        function init() {

                var fundId = $state.params.pitch_funding_id;
                PitchFundingService.retrievePitchFunding(fundId).then(function (res) {
                        $scope.fund = res.data;
                });
        }

        $scope.discover = function () {
                $state.transitionTo('discover');
        };

        init();
});
'use strict';

angular.module('uprise').controller('PitchFundingPaymentFailureController', function ($scope, $state, UserService, PitchPaymentService) {

    $scope.errorMessage = $state.params.error_message;
    $scope.pitchFundingId = $state.params.pitch_funding_id;

    if ($scope.pitchFundingId) {
        try {
            PitchPaymentService.cancelPayFastPayment($scope.pitchFundingId).then(function (res) {
                console.log(res);
            });
        } catch (e) {
            console.log(e);
        }
    }

    $scope.viewProfile = function () {
        UserService.getUserDetails().then(function (res) {
            $state.transitionTo('user-profile', { user_id: res.user_id });
        });
    };
});
'use strict';

angular.module('uprise').controller('PitchFundingPaymentController', function ($scope, $state, $window, PitchPaymentService, PitchFundingService, PitchSetupService, ExchangeService, UserService) {

    var pitchId = $state.params.pitch_id;
    var dataLayer = $window.dataLayer = $window.dataLayer || [];
    var userProfileDetails = {};

    $scope.loading_page = true;

    $scope.formData = {
        pitchInvestmentOptionId: 0,
        investmentPledged: 0
    };

    $scope.backToPitch = function () {
        $state.transitionTo('view-pitch-details', { pitch_id: pitchId });
    };

    $scope.selectStep = function ($event, i) {

        $event.preventDefault();

        switch (i) {

            case 1:
                $scope.step = 1;
                $state.transitionTo('pitch-funding.pledge', { pitch_id: pitchId });
                break;

            case 2:

                $scope.step = 2;
                $state.transitionTo('pitch-funding.assessment', { pitch_id: pitchId });
                break;

            case 3:

                $scope.step = 3;
                $state.transitionTo('pitch-funding.your_details', { pitch_id: pitchId });
                break;

            case 4:

                $scope.step = 4;
                $state.transitionTo('pitch-funding.disclaimer', { pitch_id: pitchId });
                break;

            case 6:
                $scope.step = 6;
                $state.transitionTo('pitch-funding-pledge-agreement', { pitch_id: pitchId });
                break;
        }
    };

    $scope.initiateMyGatePayment = function () {

        dataLayer.push({
            'event': 'step_paymentoption',
            'pitchId': pitchId,
            'userId': userProfileDetails.id,
            'amt': $scope.formData.investmentPledged
        });

        PitchPaymentService.initiateMyGatePayment($scope.formData.investmentPledged, $scope.pitchFundingId).then(function (res) {
            var payload = res.data;

            $('#Mode').val(payload.Mode);
            $('#MerchantID').val(encodeURIComponent(payload.MerchantID));
            $('#ApplicationID').val(encodeURIComponent(payload.ApplicationID));
            $('#MerchantReference').val(payload.MerchantReference);
            $('#Amount').val(payload.Amount);
            $('#Currency').val(payload.Currency);
            $('#RedirectSuccessfulURL').val(payload.RedirectSuccessfulURL);
            $('#RedirectFailedURL').val(payload.RedirectFailedURL);
            $('#Variable1').val(payload.Variable1);
            $('#Qty1').val(payload.Qty1);
            $('#ItemRef1').val(payload.ItemRef1);
            $('#ItemDescr1').val(payload.ItemDescr1);
            $('#ItemAmount1').val(payload.ItemAmount1);
            $('#frmPost').attr('action', payload.MyGateURL);
            $('#frmPost').submit();
        });
    };

    $scope.initiatePayPalPayment = function () {
        ExchangeService.exchange_promise(parseFloat($scope.formData.investmentPledged)).then(function (amount) {

            dataLayer.push({
                'event': 'step_paymentsuccess',
                'pitchId': pitchId,
                'userId': userProfileDetails.id,
                'amt': $scope.formData.investmentPledged
            });

            PitchPaymentService.initiatePayPalPayment(parseFloat(amount).toFixed(2), $scope.pitchFundingId, pitchId).then(function (res) {
                $window.location.href = res.data.paypal_url + res.data.payKey;
            });
        });
    };

    $scope.initiatePayFastEFTPayment = function () {
        dataLayer.push({
            'event': 'step_paymentsuccess',
            'pitchId': pitchId,
            'userId': userProfileDetails.id,
            'amt': $scope.formData.investmentPledged
        });

        PitchPaymentService.initiatePayFastEFTPayment($scope.formData.investmentPledged, $scope.pitchFundingId, pitchId).then(function (res) {
            var payload = res.data;

            $('#payfast_merchant_id').val(payload.merchant_id);
            $('#payfast_merchant_key').val(payload.merchant_key);
            $('#payfast_return_url').val(payload.return_url);
            $('#payfast_cancel_url').val(payload.cancel_url);
            $('#payfast_notify_url').val(payload.notify_url);
            $('#payfast_name_first').val(payload.name_first);
            $('#payfast_name_last').val(payload.name_last);
            $('#payfast_email_address').val(payload.email_address);
            $('#payfast_amount').val(payload.amount);
            $('#payfast_item_name').val(payload.item_name);
            $('#payfast_item_description').val(payload.item_name);
            $('#payfast_m_payment_id').val(payload.m_payment_id);
            $('#payfast_payment_method').val(payload.payment_method);
            $('#payfast_signature').val(payload.signature);
            $('#frmPayFastPost').attr('action', payload.url);
            $('#frmPayFastPost').submit();
        });
    };

    $scope.initiatePayFastCreditCardPayment = function () {
        dataLayer.push({
            'event': 'step_paymentsuccess',
            'pitchId': pitchId,
            'userId': userProfileDetails.id,
            'amt': $scope.formData.investmentPledged
        });

        PitchPaymentService.initiatePayFastCreditCardPayment($scope.formData.investmentPledged, $scope.pitchFundingId, pitchId).then(function (res) {
            var payload = res.data;

            $('#payfast_merchant_id').val(payload.merchant_id);
            $('#payfast_merchant_key').val(payload.merchant_key);
            $('#payfast_return_url').val(payload.return_url);
            $('#payfast_cancel_url').val(payload.cancel_url);
            $('#payfast_notify_url').val(payload.notify_url);
            $('#payfast_name_first').val(payload.name_first);
            $('#payfast_name_last').val(payload.name_last);
            $('#payfast_email_address').val(payload.email_address);
            $('#payfast_amount').val(payload.amount);
            $('#payfast_item_name').val(payload.item_name);
            $('#payfast_item_description').val(payload.item_name);
            $('#payfast_m_payment_id').val(payload.m_payment_id);
            $('#payfast_payment_method').val(payload.payment_method);
            $('#payfast_signature').val(payload.signature);
            $('#frmPayFastPost').attr('action', payload.url);
            $('#frmPayFastPost').submit();
        });
    };

    $scope.initiateEFTPayment = function () {
        dataLayer.push({
            'event': 'step_paymentsuccess',
            'pitchId': pitchId,
            'userId': userProfileDetails.id,
            'amt': $scope.formData.investmentPledged
        });

        PitchPaymentService.initiateEFTPayment($scope.formData.investmentPledged, $scope.pitchFundingId, pitchId).then(function (res) {
            $state.transitionTo('pitch-funding-eft-details', { pitch_funding_id: $scope.pitchFundingId });
        });
    };

    var init = function init() {

        dataLayer.push({
            'event': 'step_paymentoption',
            'pitchId': pitchId,
            'userId': userProfileDetails.id,
            'amt': $scope.formData.investmentPledged
        });

        UserService.checkUserSignedIn().then(function (res) {
            if (res) {
                //yes, signed in, retrieve their profile details
                UserService.fetchUserProfile(res.user_id).then(function (res) {
                    userProfileDetails = res.data.user_info;
                    PitchFundingService.retrievePitchIncompleteFunding(pitchId).then(function (res) {

                        $scope.pitchFundingId = res.data.id;
                        $scope.formData.pitchInvestmentOptionId = res.data.pitch_investment_option.id;
                        $scope.formData.investmentPledged = res.data.investment_pledged;
                        $scope.selectedInvestmentOption = res.data.pitch_investment_option;

                        //step 3: fetch the pitch details
                        PitchSetupService.fetchPitchDetails(pitchId).then(function (res) {
                            $scope.loading_page = false;
                            $scope.pitchDetails = res.data;
                            var end_date = moment($scope.pitchDetails.finish_date);
                            var todays_date = moment();
                            $scope.pitchDetails.days_left = end_date.diff(todays_date, 'days');
                        });
                    });
                });
            }
        });
    };

    init();
});
'use strict';

angular.module('uprise').controller('PitchFundingPaymentSuccessController', function ($scope, $state, UserService, PitchSetupService) {

    var init = function init() {
        if ($state.params.pitch) {
            PitchSetupService.fetchPitchDetails($state.params.pitch).then(function (res) {
                $scope.pitch = res.data;
            });
        }
    };

    init();

    $scope.viewProfile = function () {
        UserService.getUserDetails().then(function (res) {
            $state.transitionTo('user-profile', { user_id: res.user_id });
        });
    };
});
'use strict';

angular.module('uprise').controller('PitchFundingPledgeController', function ($scope, $state, $location, $timeout, $window, PitchService, UserService, PitchFundingService, PitchSetupService) {
    $scope.loading_page = true;
    $scope.email_verification_sent = false;
    var pitchId = $state.params.pitch_id;

    var dataLayer = $window.dataLayer = $window.dataLayer || [];

    switch ($state.current.name) {
        case 'pitch-funding.pledge':
            $scope.step = 1;
            break;
        case 'pitch-funding.assessment':
            $scope.step = 2;
            break;
        case 'pitch-funding.your_details':
            $scope.step = 3;
            break;
        case 'pitch-funding.disclaimer':
            $scope.step = 4;
            break;
        case 'pitch-funding-pledge-payment':
            $scope.step = 5;
            break;
        case 'pitch-funding.agreement':
            $scope.step = 6;
            break;
    }

    var userProfileDetails = {};
    $scope.pitchFundingId = 0;
    $scope.investorType = '';
    $scope.selectedInvestmentOption = {};
    $scope.pitchDetails = {};
    $scope.errorMessage = "";
    $scope.investmentOptions = [];
    $scope.continuedToAgreement = false;
    $scope.answersSelectedCorrectly = false;

    //variables used by tabs navigation => do not allow user to skip steps where applicable
    $scope.pledgeComplete = false;
    $scope.experienceAssessmentComplete = false;
    $scope.detailsCaptured = false;

    $scope.selectInvestorType = function (type) {
        $scope.investorType = type;
    };

    $scope.investor = {
        previous_investment_experience: null,
        id_number: null,
        identity_document: '',
        street_name_and_number: '',
        apartment_name_and_number: '',
        suburb: '',
        postcode: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        cellphone: '',
        proof_of_address: null,
        accept_t_and_c: false,
        has_green_bar_coded_id_book: null,
        country_of_residence: '',
        pitch_investment_option_id: '',
        pitch_investment_pledged: 0,
        investment_knowledge_assessment: false,
        source_of_funds: '',
        board_member: false
    };

    var previousExperienceOptions = [{
        title: 'Started a business',
        selected: false
    }, {
        title: 'Bought investment property',
        selected: false
    }, {
        title: 'Completed a e-commerce related degree',
        selected: false
    }, {
        title: 'Invested in equity',
        selected: false
    }];

    var testQuestions = [{
        title: 'What happens to most small businesses?',
        answers: [{
            title: 'They fail',
            selected: false,
            is_correct: true,
            response_message: 'That’s right, in South Africa 4 out of 5 new businesses fail within in the first 2 years.'
        }, {
            title: 'They are a huge success and make their investors tons of money',
            selected: false,
            is_correct: false,
            response_message: 'That is incorrect, in South Africa 4 out of 5 new businesses fail within in the first 2 years.'
        }]
    }, {
        title: 'If the startup I invest in fails, what happens?',
        answers: [{
            title: 'Uprise.Africa will pay back all the money I invested',
            selected: false,
            is_correct: false,
            response_message: 'That is incorrect if the business fails you will not be able to recover your investment.'
        }, {
            title: 'It is unlikely that I will get my investment back.',
            selected: false,
            is_correct: true,
            response_message: 'That’s right if the business fails you will not be able to recover your investment.'
        }]
    }, {
        title: 'After I have been issued shares, can I get my money back at any time?',
        answers: [{
            title: 'No, it typically will not be easy for me to sell my shares unless the company I have invested in has been bought or sits on the stock markets.',
            selected: false,
            is_correct: true,
            response_message: 'That is correct; small business shares can rarely be sold unless it has been listed on the stock market or exited.'
        }, {
            title: 'Yes, legally the company must pay my money back whenever I want it.',
            selected: false,
            is_correct: false,
            response_message: 'That is incorrect; small business shares can rarely be sold unless it has been listed on the stock market or exited.'
        }]
    }, {
        title: 'Do startups pay dividends?',
        answers: [{
            title: 'Yes, I can expect payment of dividends periodically.',
            selected: false,
            is_correct: false,
            response_message: 'That is incorrect; start-ups are unlikely to pay periodic dividends.'
        }, {
            title: 'No, startups are not likely to pay dividends.',
            selected: false,
            is_correct: true,
            response_message: 'That is correct; start-ups are unlikely to pay periodic dividends.'
        }]
    }, {
        title: 'If the company I invest in is successful, can I sell my shares?',
        answers: [{
            title: 'Yes, The founders of the company must buy back my shares by law.',
            selected: false,
            is_correct: false,
            response_message: 'That is incorrect; business owners are not obliged to buy back investor shares.'
        }, {
            title: 'Generally shares purchased via Equity Crowdfunding are liquid, however a liquidity event will vary based on the deal - Each deals liquidity information will be displayed on their pitch page.',
            selected: false,
            is_correct: true,
            response_message: 'That is correct; small business shares can rarely be sold unless it has been listed on the stock market or exited.'
        }]
    }];

    $scope.investmentValidation = {
        previousExperienceOptions: previousExperienceOptions,
        testQuestions: testQuestions,
        idNumberValid: null
    };

    /** SECTION 1 : SELECT INVESTMENT OPTION **/

    var init = function init() {
        if (!!pitchId) {

            UserService.checkUserSignedIn().then(function (res) {

                if (res) {
                    //yes, signed in, retrieve their profile details
                    UserService.fetchUserProfile(res.user_id).then(function (res) {
                        userProfileDetails = res.data.user_info;
                        $scope.investor.first_name = userProfileDetails.first_name;
                        $scope.investor.last_name = userProfileDetails.last_name;
                        $scope.investor.middle_name = userProfileDetails.middle_name;
                        $scope.investorType = userProfileDetails.investor_type;

                        //used by navigator tabs
                        $scope.experienceAssessmentComplete = userProfileDetails.investment_knowledge_assessment;
                        $scope.detailsCaptured = !!userProfileDetails.investment_knowledge_assessment && !!userProfileDetails.proof_of_address_url && !!userProfileDetails.id_document_url && !!userProfileDetails.suburb;

                        // if (!userProfileDetails.investor_type) {
                        //     $state.transitionTo('investor_type');
                        // }
                    });
                }
            });

            //step 1 : retrieve the different investment options for this pitch
            PitchService.retrievePitchInvestmentOptions(pitchId).then(function (res) {

                $scope.investmentOptions = res.data.investment_options;

                PitchFundingService.retrievePitchIncompleteFunding(pitchId).then(function (res2) {

                    //step 2 : check if user already has started a pledge for this pitch
                    if (!!res2 && !!res2.data && typeof res2.data.pitch_investment_option != 'undefined') {

                        if (!!res2.data.investor) {

                            //the user has already started the investment journey for this pitch, but has not completed it
                            $scope.investor.previous_investment_experience = res2.data.investor.previous_investment_experience;
                            $scope.investor.investing_as = res2.data.investor.investing_as;
                            $scope.investor.cellphone = res2.data.investor.cellphone;

                            $scope.investor.id_number = res2.data.investor.id_number;
                            $scope.investor.has_green_bar_coded_id_book = res2.data.investor.has_green_bar_coded_id_book || null;
                            $scope.investor.country_of_residence = res2.data.investor.country_of_residence || '';

                            $scope.investmentValidation.idNumberValid = true;
                            $scope.investor.identity_document = res2.data.investor.id_document_url;
                            $scope.investor.street_name_and_number = res2.data.investor.street_name_and_number;
                            $scope.investor.apartment_name_and_number = res2.data.investor.apartment_name_and_number;
                            $scope.investor.suburb = res2.data.investor.suburb;
                            $scope.investor.postcode = res2.data.investor.postcode;
                            $scope.investor.proof_of_address = res2.data.investor.proof_of_address_url;
                            $scope.investor.company_cert = res2.data.investor.company_cert;
                            $scope.investor.investment_knowledge_assessment = res2.data.investor.investment_knowledge_assessment;
                            $scope.investor.source_of_funds = res2.data.investor.source_of_funds;

                            $scope.investor.company_cert_doc = res2.data.investor.company_cert_doc;
                            $scope.investor.company_registration_number = res2.data.investor.company_registration_number;
                            $scope.investor.company_registered_name = res2.data.investor.company_registered_name;
                            $scope.investor.director_contact_number = res2.data.investor.director_contact_number;

                            $scope.investor.trust_name = res2.data.investor.trust_name;
                            $scope.investor.trust_contact_number = res2.data.investor.trust_contact_number;
                            $scope.investor.trust_deeds = res2.data.investor.trust_deeds;
                            $scope.investor.trust_letter_of_authority = res2.data.investor.trust_letter_of_authority;
                            $scope.investor.trust_changes = res2.data.investor.trust_changes;
                            $scope.investor.trust_owner_ids = res2.data.investor.trust_owner_ids;
                            $scope.investor.trust_proof_of_address = res2.data.investor.trust_proof_of_address;
                            $scope.investor.trust_confirm_location = res2.data.investor.trust_confirm_location;
                        }

                        $scope.pitchFundingId = res2.data.id;
                        $scope.investor.pitch_investment_option_id = res2.data.pitch_investment_option.id;
                        $scope.investor.pitch_investment_pledged = res2.data.investment_pledged;
                        $scope.selectedInvestmentOption = res2.data.pitch_investment_option;
                        $scope.pledgeComplete = !!$scope.investor.pitch_investment_pledged && !!res2.data.pitch_investment_option;
                    } else {
                        setDefaultInvestment();
                    }

                    //step 3: fetch the pitch details
                    PitchSetupService.fetchPitchDetails(pitchId).then(function (res3) {
                        $scope.pitchDetails = res3.data;
                        var end_date = moment($scope.pitchDetails.finish_date);
                        var todays_date = moment();
                        $scope.pitchDetails.days_left = end_date.diff(todays_date, 'days');
                    });

                    $scope.loading_page = false;
                });
            }).catch(function (exception) {
                console.log(exception);
            });
        }

        $(document).ready(function () {
            $('.modal').modal();
        });
    };

    function setDefaultInvestment() {

        if (!!$scope.investmentOptions && $scope.investmentOptions.length > 0) {

            $scope.investor.pitch_investment_option_id = $scope.investmentOptions[0].id;
            $scope.investor.pitch_investment_pledged = $scope.investmentOptions[0].minimum_investment;
            $scope.selectedInvestmentOption = $scope.investmentOptions[0];
        }
    }

    init();

    $scope.verifyUserProfile = function () {
        UserService.verifyUserProfile($location.path()).then(function () {
            $timeout(function () {
                $scope.email_verification_sent = true;
            }, 0);
        });
    };

    //this is invoked when a user selects an investment-type radio button
    $scope.setInvestmentOption = function (selectedInvestmentOptionId) {

        $scope.investor.pitch_investment_option_id = selectedInvestmentOptionId;

        $scope.investmentOptions.forEach(function (option) {
            if (option.id === selectedInvestmentOptionId) {

                //set the default investment amount to the minimum amount of this share type
                if ($scope.pitchFundingId === 0) {
                    $scope.investor.pitch_investment_pledged = option.minimum_investment;
                }

                $scope.selectedInvestmentOption = option;
            }
        });
    };

    //this is invoked when the investor selects which share they want to invest in (i.e. clicks 'continue' on step 1)
    $scope.pledgeInvestment = function () {

        $('.modal').modal();

        dataLayer.push({
            'event': 'step_chooseamount',
            'pitchId': pitchId,
            'userId': userProfileDetails.id,
            'amt': $scope.investor.pitch_investment_pledged
        });

        if (userProfileDetails.email_verified) {
            saveInvestment().then(function (res) {

                $scope.pledgeComplete = true;

                if (!userProfileDetails.investment_knowledge_assessment) {
                    $scope.step = 2;
                    $state.transitionTo('pitch-funding.assessment', { pitch_id: pitchId });
                    return;
                }

                if (userProfileDetails.investment_knowledge_assessment && !userProfileDetails.proof_of_address_url && !userProfileDetails.id_document_url && !userProfileDetails.suburb) {
                    $scope.step = 3;
                    $state.transitionTo('pitch-funding.your_details', { pitch_id: pitchId });
                    return;
                }

                if (userProfileDetails.proof_of_address_url && userProfileDetails.id_document_url && userProfileDetails.suburb) {
                    $scope.step = 4;
                    $state.transitionTo('pitch-funding.disclaimer', { pitch_id: pitchId });
                    return;
                }

                if (userProfileDetails.proof_of_address_url && userProfileDetails.suburb) {
                    $scope.step = 4;
                    $state.transitionTo('pitch-funding.disclaimer', { pitch_id: pitchId });
                    return;
                }
            }, function (error) {

                console.log(error);
                $scope.errorMessage = error;
                $scope.$apply();
                $('#modalInvestmentError').modal('open');
            });
        } else {
            $('#emailAddressNotVerified').modal('open');
        }
    };

    $scope.navigateToProfile = function () {
        $('#emailAddressNotVerified').modal('close');
        $state.transitionTo('user-profile', { user_id: userProfileDetails.id });
    };

    //this is invoked when the investor selects which share they want to invest in (i.e. clicks 'save and exit')
    $scope.pledgeAndExit = function ($event) {

        $event.preventDefault();

        saveInvestment().then(function (res) {
            $state.transitionTo('home');
        }, function (error) {

            console.log(error);
            $scope.errorMessage = error;
            $scope.$apply();
            $('#modalInvestmentError').modal('open');
        });
    };

    //set class on tab navigator buttons
    $scope.setActiveClass = function (i) {

        if (i === 2 && $scope.pledgeComplete === false) {
            return 'inactive';
        }
        if (i === 3 && $scope.experienceAssessmentComplete === false) {
            return 'inactive';
        }
        if (i === 4 && $scope.detailsCaptured === false) {
            return 'inactive';
        }
        if (i === 5 && ($scope.pledgeComplete === false || $scope.experienceAssessmentComplete === false || $scope.detailsCaptured === false || !$scope.investor.accept_t_and_c)) {
            return 'inactive';
        }
        if (i === 6 && ($scope.pledgeComplete === false || $scope.experienceAssessmentComplete === false || $scope.detailsCaptured === false || $scope.continuedToAgreement === false)) {
            return 'inactive';
        }

        if (i === $scope.step) {
            return 'active';
        }
        return '';
    };

    $scope.backToPitch = function () {
        $state.transitionTo('view-pitch-details', { pitch_id: pitchId });
    };

    //click on tab navigator button
    $scope.selectStep = function ($event, i) {

        if (userProfileDetails.email_verified) {
            $event.preventDefault();

            switch (i) {

                case 1:
                    $scope.step = 1;
                    $state.transitionTo('pitch-funding.pledge', { pitch_id: pitchId });
                    break;

                case 2:

                    if (!$scope.pledgeComplete) {
                        return;
                    }

                    $scope.step = 2;
                    $state.transitionTo('pitch-funding.assessment', { pitch_id: pitchId });
                    break;

                case 3:

                    if (!$scope.pledgeComplete || !$scope.experienceAssessmentComplete) {
                        return;
                    }

                    $scope.step = 3;
                    $state.transitionTo('pitch-funding.your_details', { pitch_id: pitchId });
                    break;

                case 4:

                    if (!$scope.pledgeComplete || !$scope.experienceAssessmentComplete || !$scope.detailsCaptured) {
                        return;
                    }

                    $scope.step = 4;
                    $state.transitionTo('pitch-funding.disclaimer', { pitch_id: pitchId });
                    break;

                case 5:

                    if (!$scope.pledgeComplete || !$scope.experienceAssessmentComplete || !$scope.detailsCaptured || !userProfileDetails.accept_t_and_c) {
                        return;
                    }

                    $scope.step = 5;
                    $state.transitionTo('pitch-funding-pledge-payment', { pitch_id: pitchId });
                    break;

                case 6:
                    if (!$scope.pledgeComplete || !$scope.experienceAssessmentComplete || !$scope.detailsCaptured || !$scope.continuedToAgreement) {
                        return;
                    }

                    $scope.step = 6;
                    $state.transitionTo('pitch-funding.agreement', { pitch_id: pitchId });
                    break;

            }
        }
    };

    $scope.selectedOption = function (optionId) {
        return parseInt($scope.investor.pitch_investment_option_id) == parseInt(optionId) ? 'selected-option' : '';
    };

    function saveInvestment() {

        return new Promise(function (resolve, reject) {

            var shareCapital = $scope.investor.pitch_investment_pledged && !isNaN($scope.investor.pitch_investment_pledged) ? parseFloat($scope.investor.pitch_investment_pledged) : 0; //amount they want to invest
            var fundingGoalCapital = $scope.pitchDetails.funding_goal_capital || 1; //amount company is raising
            var fundingGoalEquity = $scope.pitchDetails.funding_goal_equity || 0; //equity company is giving away for this amount they are raising
            var maxEquityAvailable = $scope.pitchDetails.max_equity_available || 0; //the max amount of equity they are prepared to give away
            var equityDivested = $scope.pitchDetails.equity_reserved || 0; //amount of equity given away so far
            var equityPurchased = fundingGoalEquity / fundingGoalCapital * shareCapital; //amount of equity this user will receive for their investment

            if (equityPurchased + equityDivested > maxEquityAvailable) {
                reject('Unfortunately the investment pledged exceeds amount of remaining equity available.');
                return;
            }

            var shareRandValue = 0;

            for (var i = 0; i < $scope.investmentOptions.length; i++) {
                if ($scope.investmentOptions[i].id === $scope.investor.pitch_investment_option_id) {

                    //share value should never be 0!
                    shareRandValue = $scope.investmentOptions[i].rand_value_per_share || 0;
                    if (shareRandValue === 0) {
                        reject('Unfortunately there an error has occurred, please contact Uprise.');
                    }

                    //check min or max investment limits not exceeded
                    if (shareCapital < $scope.investmentOptions[i].minimum_investment) {
                        reject('Unfortunately the investment pledged does not meet minimum permitted amount. Please note that the minimum investment amount for the selected share type is \n                                R' + $scope.investmentOptions[i].minimum_investment + '.');
                        return;
                    } else if (shareCapital > $scope.investmentOptions[i].maximum_investment) {
                        reject('Unfortunately the investment pledged exceeds maximum permitted amount. Please note that the maximum investment amount for the selected share type is \n                                R' + $scope.investmentOptions[i].maximum_investment + '.');
                        return;
                    }

                    //check for round share amount (cannot receive fraction of a share)
                    if (shareCapital % shareRandValue !== 0) {

                        while (shareCapital % shareRandValue !== 0) {
                            shareCapital -= 1;
                        }

                        var sharesReceivedForInvestmentMade = shareCapital / shareRandValue;
                        $scope.investor.pitch_investment_pledged = shareCapital;

                        reject('We have automatically decreased your investment amount to R' + shareCapital + ', to ensure that the number of shares you receive is a round number i.e. ' + sharesReceivedForInvestmentMade + '. \n                                Please confirm that this is alright by again clicking the \'Continue\' button.');
                        return;
                    }
                }
            }

            if ($scope.pitchFundingId === 0) {
                //this is a new funding
                resolve(PitchFundingService.addPitchFundingPledge(pitchId, $scope.investor.pitch_investment_option_id, $scope.investor.pitch_investment_pledged));
            } else {
                //this is an existing funding
                resolve(PitchFundingService.updatePitchFundingPledge($scope.pitchFundingId, pitchId, $scope.investor.pitch_investment_option_id, $scope.investor.pitch_investment_pledged));
            }
        });
    };

    /** SECTION 2 : SPECIFY INVESTMENT EXPERIENCE **/

    $scope.setAnswer = function (questionIndex, answerIndex) {
        $scope.investmentValidation.testQuestions[questionIndex].answers.forEach(function (answer) {
            answer.selected = false;
        });
        $scope.investmentValidation.testQuestions[questionIndex].answers[answerIndex].selected = true;

        var isValid = true;
        $scope.investmentValidation.testQuestions.forEach(function (question) {
            question.answers.forEach(function (answer) {
                if (!answer.selected && answer.is_correct) {
                    isValid = false;
                }
            });
        });

        if (isValid) {
            $scope.answersSelectedCorrectly = true;
        }
    };

    //Save the user's investment experience to the database
    $scope.saveInvestmentExperience = function (exit) {

        dataLayer.push({
            'event': 'step_assessment',
            'pitchId': pitchId,
            'userId': userProfileDetails.id,
            'amt': $scope.investor.pitch_investment_pledged
        });

        //do not allow them to continue if they answer the questions incorrectly
        var isValid = true;
        $scope.investmentValidation.testQuestions.forEach(function (question) {

            question.answers.forEach(function (answer) {
                if (!answer.selected && answer.is_correct) {
                    isValid = false;
                }
            });
        });

        if (!isValid) {
            $scope.errorMessage = "Please ensure you have responded correctly to the questions in the 'Your knowledge' section.";
            $('#modalInvestmentError').modal('open');
            return;
        }

        if (!$scope.investor) {
            $scope.errorMessage = "Please indicate if you have any investment experience.";
            $('#modalInvestmentError').modal('open');
            return;
        }

        $scope.investor.previous_investment_experience = !!$scope.investor.previous_investment_experience; // null or undefined becomes false

        //retrieve their previous investment experience
        var previousInvestments = [];
        $scope.investmentValidation.previousExperienceOptions.forEach(function (item) {
            if (item.selected) {
                previousInvestments.push(item.title);
            }
        });

        //invoke API to save
        UserService.setUserInvestmentExperience($scope.investor.previous_investment_experience, previousInvestments.join(','), $scope.investorType).then(function () {

            //mark experience assessment as completed => affects tab navigator
            $scope.experienceAssessmentComplete = true;
            $scope.step = 3;

            if (exit) {
                $state.transitionTo('home');
            } else {
                $state.transitionTo('pitch-funding.your_details', { pitch_id: pitchId });
            }
        });
    };

    /** SECTION 3 : UPDATE INVESTOR KYC DATA **/

    $scope.validateSAIdentityNumber = function () {

        console.log('validateSAIdentityNumber');
        if (!$scope.investor || !$scope.investor.id_number) {
            $scope.investmentValidation.idNumberValid = false;
            return false;
        }

        var id_number = $scope.investor.id_number + ''; //convert it into a string

        //1. numeric and 13 digits
        if (isNaN(id_number) || id_number.length != 13) {
            $scope.investmentValidation.idNumberValid = false;
            return false;
        }
        //2. first 6 numbers is a valid date
        var date = new Date(parseInt(id_number.substring(0, 2)), parseInt(id_number.substring(2, 4)) - 1, parseInt(id_number.substring(4, 6)));

        if (date.getYear() !== parseInt(id_number.substring(0, 2)) || date.getMonth() !== parseInt(id_number.substring(2, 4)) - 1 || date.getDate() !== parseInt(id_number.substring(4, 6))) {
            $scope.investmentValidation.idNumberValid = false;
            return false;
        }

        //3. luhn formula
        var tempTotal = 0;
        var checkSum = 0;
        var multiplier = 1;
        for (var i = 0; i < 13; ++i) {
            tempTotal = parseInt(id_number.charAt(i)) * multiplier;
            if (tempTotal > 9) {
                tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
            }
            checkSum = checkSum + tempTotal;
            multiplier = multiplier % 2 == 0 ? 1 : 2;
        }
        if (checkSum % 10 == 0) {
            $scope.investmentValidation.idNumberValid = true;
            return true;
        }
        $scope.investmentValidation.idNumberValid = false;
        return false;
    };

    $scope.idDocumentUploaded = function (obj, event) {

        var item = event.target;
        var file = $(item)[0].files[0];
        var upload = new Upload(file);

        jQuery('#id_upload_progress_parent').toggle();

        // TODO: check size or type here with upload.getSize() and upload.getType()
        upload.doUpload().then(function (docUrl) {
            jQuery('#id_upload_progress_parent').toggle();
            $scope.investor.identity_document = docUrl;
            $scope.$apply();
        }, function (err) {
            jQuery('#id_upload_progress_parent').toggle();
            console.log(err);
        });
    };

    $scope.addressDocumentUploaded = function (obj, event) {

        var item = event.target;
        var file = $(item)[0].files[0];
        var upload = new Upload(file);

        jQuery('#address_upload_progress_parent').toggle();

        // TODO: check size or type here with upload.getSize() and upload.getType()
        upload.doUpload().then(function (docUrl) {
            jQuery('#address_upload_progress_parent').toggle();
            $scope.investor.proof_of_address = docUrl;
            $scope.$apply();
        }, function (err) {
            jQuery('#address_upload_progress_parent').toggle();
            console.log(err);
        });
    };

    $scope.companyCertDocumentUploaded = function (obj, event) {

        var item = event.target;
        var file = $(item)[0].files[0];
        var upload = new Upload(file);

        jQuery('#company_cert_upload_progress_parent').toggle();

        // TODO: check size or type here with upload.getSize() and upload.getType()
        upload.doUpload().then(function (docUrl) {
            jQuery('#company_cert_upload_progress_parent').toggle();
            $scope.investor.company_cert = docUrl;
            $scope.$apply();
        }, function (err) {
            jQuery('#company_cert_upload_progress_parent').toggle();
            console.log(err);
        });
    };

    $scope.trustDeedsDocumentUploaded = function (obj, event) {

        var item = event.target;
        var file = $(item)[0].files[0];
        var upload = new Upload(file);

        jQuery('#trust_deeds_upload_progress_parent').toggle();

        // TODO: check size or type here with upload.getSize() and upload.getType()
        upload.doUpload().then(function (docUrl) {
            jQuery('#trust_deeds_upload_progress_parent').toggle();
            $scope.investor.trust_deeds = docUrl;
            $scope.$apply();
        }, function (err) {
            jQuery('#trust_deeds_upload_progress_parent').toggle();
            console.log(err);
        });
    };

    $scope.trustLetterOfAuthorityDocumentUploaded = function (obj, event) {

        var item = event.target;
        var file = $(item)[0].files[0];
        var upload = new Upload(file);

        jQuery('#trust_letter_of_authority_upload_progress_parent').toggle();

        // TODO: check size or type here with upload.getSize() and upload.getType()
        upload.doUpload().then(function (docUrl) {
            jQuery('#trust_letter_of_authority_upload_progress_parent').toggle();
            $scope.investor.trust_letter_of_authority = docUrl;
            $scope.$apply();
        }, function (err) {
            jQuery('#trust_letter_of_authority_upload_progress_parent').toggle();
            console.log(err);
        });
    };

    $scope.trustChangesDocumentUploaded = function (obj, event) {

        var item = event.target;
        var file = $(item)[0].files[0];
        var upload = new Upload(file);

        jQuery('#trust_changes_upload_progress_parent').toggle();

        // TODO: check size or type here with upload.getSize() and upload.getType()
        upload.doUpload().then(function (docUrl) {
            jQuery('#trust_changes_upload_progress_parent').toggle();
            $scope.investor.trust_changes = docUrl;
            $scope.$apply();
        }, function (err) {
            jQuery('#trust_changes_upload_progress_parent').toggle();
            console.log(err);
        });
    };

    $scope.trustOwnerIdDocumentUploaded = function (obj, event) {

        var item = event.target;
        var file = $(item)[0].files[0];
        var upload = new Upload(file);

        jQuery('#trust_owner_ids_upload_progress_parent').toggle();

        // TODO: check size or type here with upload.getSize() and upload.getType()
        upload.doUpload().then(function (docUrl) {
            jQuery('#trust_owner_ids_upload_progress_parent').toggle();
            $scope.investor.trust_owner_ids = docUrl;
            $scope.$apply();
        }, function (err) {
            jQuery('#trust_owner_ids_upload_progress_parent').toggle();
            console.log(err);
        });
    };

    $scope.trustProofOfAddressDocumentUploaded = function (obj, event) {

        var item = event.target;
        var file = $(item)[0].files[0];
        var upload = new Upload(file);

        jQuery('#trust_proof_of_address_upload_progress_parent').toggle();

        // TODO: check size or type here with upload.getSize() and upload.getType()
        upload.doUpload().then(function (docUrl) {
            jQuery('#trust_proof_of_address_upload_progress_parent').toggle();
            $scope.investor.trust_proof_of_address = docUrl;
            $scope.$apply();
        }, function (err) {
            jQuery('#trust_proof_of_address_upload_progress_parent').toggle();
            console.log(err);
        });
    };

    $scope.trustConfirmLocationDocumentUploaded = function (obj, event) {

        var item = event.target;
        var file = $(item)[0].files[0];
        var upload = new Upload(file);

        jQuery('#trust_confirm_location_upload_progress_parent').toggle();

        // TODO: check size or type here with upload.getSize() and upload.getType()
        upload.doUpload().then(function (docUrl) {
            jQuery('#trust_confirm_location_upload_progress_parent').toggle();
            $scope.investor.trust_confirm_location = docUrl;
            $scope.$apply();
        }, function (err) {
            jQuery('#trust_confirm_location_upload_progress_parent').toggle();
            console.log(err);
        });
    };

    $scope.setKYCDetails = function (exit) {

        dataLayer.push({
            'event': 'step_details',
            'pitchId': pitchId,
            'userId': userProfileDetails.id,
            'amt': $scope.investor.pitch_investment_pledged
        });

        if ($scope.investor.investing_as === 'individual' || $scope.investor.investing_as === 'sa_company') {
            if (!$scope.investor.id_number || !$scope.investor.identity_document || !$scope.investor.street_name_and_number || !$scope.investor.proof_of_address) {
                $scope.errorMessage = "Please ensure that required fields are completed, and that all requested documents are uploaded.";
                $('#modalInvestmentError').modal('open');
                return;
            }

            if (!$scope.investmentValidation.idNumberValid && $scope.investor.has_green_bar_coded_id_book === true) {
                $scope.errorMessage = "Please enter a valid ID number.";
                $('#modalInvestmentError').modal('open');
                return;
            }
        }

        UserService.setUserKYCDetails($scope.investor, pitchId).then(function () {

            //mark KYC details as completed => affects tab navigator
            $scope.detailsCaptured = true;
            $scope.step = 4;

            if (exit) {
                $state.transitionTo('home');
            } else {
                $state.transitionTo('pitch-funding.disclaimer', { pitch_id: pitchId });
            }
        });
    };

    /** SECTION 4 : DISCLAIMERS **/

    $scope.continueToAgreement = function () {

        dataLayer.push({
            'event': 'step_disclaimer',
            'pitchId': pitchId,
            'userId': userProfileDetails.id,
            'amt': $scope.investor.pitch_investment_pledged
        });

        if (!$scope.investor.accept_t_and_c) {
            $scope.errorMessage = "Please confirm you have read and agree to these terms and conditions";
            $('#modalInvestmentError').modal('open');
            return;
        }

        $scope.continuedToAgreement = true;

        $state.transitionTo('pitch-funding-pledge-agreement', { pitch_id: pitchId });
    };
});
'use strict';

angular.module('uprise').factory('PitchFundingService', function ($http, $q) {

    var service = {

        retrievePitchIncompleteFunding: function retrievePitchIncompleteFunding(pitchId) {

            var req = {
                method: 'GET',
                url: '/api/pitch_funding/incomplete/' + pitchId,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000
            };

            return $http(req);
        },

        retrievePitchFunding: function retrievePitchFunding(pitchFundingId) {

            var req = {
                method: 'GET',
                url: '/api/pitch_funding/pledge/' + pitchFundingId,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000
            };

            return $http(req);
        },

        addPitchFundingPledge: function addPitchFundingPledge(pitchId, pitchInvestmentOptionId, investmentPledged) {

            var req = {
                method: 'POST',
                url: '/api/pitch_funding/pledge/',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'pitch_id': pitchId,
                    'pitch_investment_option': pitchInvestmentOptionId,
                    'investment_pledged': investmentPledged
                },
                timeout: 60000
            };

            return $http(req);
        },

        updatePitchFundingPledge: function updatePitchFundingPledge(fundingId, pitchId, pitchInvestmentOptionId, investmentPledged) {

            var req = {
                method: 'PUT',
                url: '/api/pitch_funding/pledge/' + fundingId,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'pitch_id': pitchId,
                    'pitch_investment_option': pitchInvestmentOptionId,
                    'investment_pledged': investmentPledged
                },
                timeout: 60000
            };

            return $http(req);
        },

        updatePitchFundingBoardMember: function updatePitchFundingBoardMember(board_member, fundingId) {
            var req = {
                method: 'POST',
                url: '/api/pitch_funding/board_member/' + fundingId,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'board_member': board_member
                },
                timeout: 60000
            };

            return $http(req);
        },

        generateDocument: function generateDocument(investmentPledged, pitchFundingId) {
            var req = {
                method: 'POST',
                url: '/api/pitch_funding/generate_document/' + pitchFundingId,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'investment_pledged': investmentPledged
                },
                timeout: 60000
            };

            return $http(req);
        }

    };

    return service;
});
'use strict';

angular.module('uprise').factory('PitchPaymentService', function ($http) {

    var service = {

        initiateMyGatePayment: function initiateMyGatePayment(pledged_amount, pitch_funding_id) {
            var req = {
                method: 'POST',
                url: '/api/mygate/initiate_payment',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'pledged_amount': pledged_amount,
                    'pitch_funding_id': pitch_funding_id
                }
            };

            return $http(req);
        },

        initiatePayFastCreditCardPayment: function initiatePayFastCreditCardPayment(pledged_amount, pitch_funding_id, pitch_id) {
            var req = {
                method: 'POST',
                url: '/api/payfast/initiate_credit_card_payment',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'pledged_amount': pledged_amount,
                    'pitch_funding_id': pitch_funding_id,
                    'pitch_id': pitch_id
                }
            };

            return $http(req);
        },

        initiatePayFastEFTPayment: function initiatePayFastEFTPayment(pledged_amount, pitch_funding_id, pitch_id) {
            var req = {
                method: 'POST',
                url: '/api/payfast/initiate_eft_payment',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'pledged_amount': pledged_amount,
                    'pitch_funding_id': pitch_funding_id,
                    'pitch_id': pitch_id
                }
            };

            return $http(req);
        },

        cancelPayFastPayment: function cancelPayFastPayment(pitch_funding_id) {
            var req = {
                method: 'POST',
                url: '/api/payfast/transaction_cancelled/' + pitch_funding_id,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {}
            };

            return $http(req);
        },

        initiateEFTPayment: function initiateEFTPayment(pledged_amount, pitch_funding_id, pitch_id) {
            var req = {
                method: 'POST',
                url: '/api/eft/initiate_payment',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'pledged_amount': pledged_amount,
                    'pitch_funding_id': pitch_funding_id,
                    'pitch_id': pitch_id
                }
            };

            return $http(req);
        },

        initiatePayPalPayment: function initiatePayPalPayment(pledged_amount, pitch_funding_id, pitch_id) {
            var req = {
                method: 'POST',
                url: '/api/paypal/initiate_payment',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'pledged_amount': pledged_amount,
                    'pitch_funding_id': pitch_funding_id,
                    'pitch_id': pitch_id
                }
            };

            return $http(req);
        }

    };

    return service;
});
'use strict';

angular.module('uprise').controller('PitchDetailsController', function ($scope, $state, $sce, $timeout, $anchorScroll, UserService, PitchSetupService, PitchService) {

    var pitchId = $state.params.pitch_id;
    var userAuthed = false;

    $scope.loading_page = true;

    $scope.pitchDetails = {};

    $scope.trustSrc = function (url) {
        return $sce.trustAsResourceUrl(url);
    };

    $scope.trustHtml = function (html) {
        return $sce.trustAsHtml(html);
    };

    $scope.$on('handleLoginStateChangeBroadcast', function (event, args) {
        var userAuthed = true;
    });

    var init = function init() {
        $('.modal').modal();

        if ($state.params.tmtData) {
            console.log($state.params.tmtData);
            localStorage.setItem('tmtData', $state.params.tmtData);
            UserService.checkUserSignedIn().then(function (res) {
                if (res) {
                    UserService.saveAdmarulaToken($state.params.tmtData).then(function () {
                        localStorage.removeItem('tmtData');
                    });
                }
            });
        }

        PitchSetupService.fetchPitchDetails(pitchId).then(function (res) {
            $scope.pitchDetails = res.data;

            $scope.pitchDetails.story = $scope.trustHtml($scope.pitchDetails.story);
            $scope.pitchDetails.short_blurb = $scope.trustHtml($scope.pitchDetails.short_blurb);
            $scope.pitchDetails.funding_goal_equity = $scope.pitchDetails.funding_goal_equity * 100;

            var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (2 || -1) + '})?');
            $scope.pitchDetails.funding_goal_equity = $scope.pitchDetails.funding_goal_equity.toString().match(re)[0];

            if ($scope.pitchDetails.video) {
                var videoSplit = $scope.pitchDetails.video.split("/");

                if (videoSplit.length > 0) {

                    if ($scope.pitchDetails.video.indexOf("youtu") > -1) {

                        var videoID = videoSplit[videoSplit.length - 1];

                        videoID = videoID.replace("watch?v=", "");

                        $scope.pitchDetails.video = 'https://www.youtube.com/embed/' + videoID;
                    } else if ($scope.pitchDetails.video.indexOf("vimeo") > -1) {

                        var _videoID = videoSplit[videoSplit.length - 1];
                        $scope.pitchDetails.video = 'https://player.vimeo.com/video/' + _videoID;
                    }
                }
            }

            var end_date = moment($scope.pitchDetails.finish_date);
            var todays_date = moment();
            $scope.pitchDetails.days_left = end_date.diff(todays_date, 'days');

            $scope.loading_page = false;

            if (!!$scope.pitchDetails.social_media && !!$scope.pitchDetails.social_media) {
                $scope.pitchDetails.social_media.short_blurb = $scope.trustHtml($scope.pitchDetails.social_media.short_blurb);
            }

            angular.forEach($scope.pitchDetails.company_overview, function (company) {
                company.short_blurb = $scope.trustHtml(company.short_blurb);
                company.long_blurb = $scope.trustHtml(company.long_blurb);
            });

            angular.forEach($scope.pitchDetails.customer_traction, function (customer) {
                customer.short_blurb = $scope.trustHtml(customer.short_blurb);
                customer.long_blurb = $scope.trustHtml(customer.long_blurb);
            });

            angular.forEach($scope.pitchDetails.financials, function (financial) {
                financial.short_blurb = $scope.trustHtml(financial.short_blurb);
                financial.long_blurb = $scope.trustHtml(financial.long_blurb);
            });

            angular.forEach($scope.pitchDetails.competitor_analysis, function (competitor) {
                competitor.short_blurb = $scope.trustHtml(competitor.short_blurb);
                competitor.long_blurb = $scope.trustHtml(competitor.long_blurb);
            });

            angular.forEach($scope.pitchDetails.team_members, function (team_member) {
                team_member.short_blurb = $scope.trustHtml(team_member.short_blurb);
            });
        }).then(function () {
            $timeout(function () {
                var stickyNav = $('#pitch-menu').offset().top - 50;
                $('#back-to-top').hide();

                $(window).scroll(function () {
                    if ($(window).scrollTop() > stickyNav) {
                        $('#pitch-menu').addClass('stick-pitch-nav');
                        $('#back-to-top').show();
                    } else {
                        $('#pitch-menu').removeClass('stick-pitch-nav');
                        $('#back-to-top').hide();
                    }
                });
                $('.scrollspy').scrollSpy();
            }, 0);

            if ($scope.pitchDetails && $scope.pitchDetails.id) {
                PitchService.retrievePitchInvestorsCount($scope.pitchDetails.id).then(function (res) {
                    $scope.pitchInvestors = res.data.investors;
                });
            }
        });
    };

    init();

    $scope.invest = function () {

        if (!UserService.isAuthenticated()) {
            return $state.transitionTo('signin', {
                previousState: {
                    name: $state.current.name,
                    param_name: 'pitch_id',
                    param_id: pitchId
                }
            });
        }

        $state.transitionTo('pitch-funding.pledge', { pitch_id: pitchId });
    };

    $scope.authSignIn = function () {
        return $state.transitionTo('signin', {
            previousState: {
                name: $state.current.name,
                param_name: 'pitch_id',
                param_id: pitchId
            }
        });
    };

    $scope.authSignUp = function () {
        return $state.transitionTo('signup', {
            previousState: {
                name: $state.current.name,
                param_name: 'pitch_id',
                param_id: pitchId
            }
        });
    };

    $scope.backToTop = function () {
        $anchorScroll('top');
    };

    $scope.notAvailableModal = function () {
        $('#notAvailable').modal('open');
    };
});
'use strict';

angular.module('uprise').controller('PitchSetupController', function ($scope, $state, PitchService, PitchSetupService, UserService) {

    var pitch_id = $state.params.pitch_id;
    $scope.pitchDetails = {};
    $scope.pitchCategories = {};
    $scope.documents = [];
    $scope.uploadError = false;
    $scope.otherSelected = false;
    $scope.uploadErrorMessage = '';

    var init = function init() {
        UserService.checkUserSignedIn().then(function (res) {
            if (res) {
                PitchService.fetchPitchCategories().then(function (res) {
                    $scope.pitchCategories = res.data.categories;
                    PitchSetupService.fetchPitchCreationDetails(pitch_id).then(function (res) {
                        $scope.pitchDetails = res.data;
                        if (!!$scope.pitchDetails.category) {
                            $scope.pitchDetails.category = $scope.pitchDetails.category.id;
                            $scope.handleSelectCategory();
                        }
                        PitchSetupService.fetchPitchDocuments(pitch_id).then(function (res) {
                            $scope.documents = res.data.documents;
                        });
                    });
                });
            } else {
                $state.transitionTo('signin');
            }
        });
    };

    init();

    $scope.handleSelectCategory = function () {
        $scope.otherSelected = false;

        angular.forEach($scope.pitchCategories, function (category) {
            if (category.name === 'Other') {
                if ($scope.pitchDetails.category === category.id) {
                    return $scope.otherSelected = true;
                }
            }
        });
    };

    $scope.docUploaded = function (obj, id, event) {

        $scope.uploadError = false;
        $scope.uploadErrorMessage = '';

        var item = event.target;
        var file = $(item)[0].files[0];
        var upload = new Upload(file);

        // TODO: check size or type here with upload.getSize() and upload.getType()
        console.log(upload.getSize());
        console.log(upload.getType());

        jQuery('#progress_parent_' + id).toggle();

        upload.doUpload().then(function (docUrl) {
            jQuery('#progress_parent_' + id).toggle();
            $scope.documents.push({
                "document_name": upload.getName(),
                "document_url": docUrl
            });
            $scope.$apply();
        }, function (err) {
            jQuery('#progress_parent_' + id).toggle();
            console.log(err);
            $scope.uploadError = true;
            $scope.uploadErrorMessage = err.responseText;
            $scope.$apply();
        });
    };

    $scope.submitForm = function (submission) {

        var details = {
            name: $scope.pitchDetails.name,
            contact_number: $scope.pitchDetails.contact_number,
            website: $scope.pitchDetails.website,
            category: $scope.pitchDetails.category,
            location: $scope.pitchDetails.location,
            funding_goal_capital: $scope.pitchDetails.funding_goal_capital,
            duration: $scope.pitchDetails.duration,
            investor_docs: $scope.documents,

            other_category_name: $scope.pitchDetails.other_category_name,

            high_level_pitch: $scope.pitchDetails.high_level_pitch,
            unique_value_proposition: $scope.pitchDetails.unique_value_proposition,
            solving_problem: $scope.pitchDetails.solving_problem,
            how_will_it_change_lives: $scope.pitchDetails.how_will_it_change_lives,
            estimated_market_size: $scope.pitchDetails.estimated_market_size,
            your_solution: $scope.pitchDetails.your_solution,
            product_features: $scope.pitchDetails.product_features,
            own_ip: $scope.pitchDetails.own_ip,

            main_competitors: $scope.pitchDetails.main_competitors,
            global_competitors: $scope.pitchDetails.global_competitors,
            how_are_you_different: $scope.pitchDetails.how_are_you_different,

            do_you_have_customers: $scope.pitchDetails.do_you_have_customers,
            do_you_have_contracts: $scope.pitchDetails.do_you_have_contracts,
            do_you_have_pilots: $scope.pitchDetails.do_you_have_pilots,

            team_size: $scope.pitchDetails.team_size,
            how_many_team_members_currently_working: $scope.pitchDetails.how_many_team_members_currently_working,
            team_details: $scope.pitchDetails.team_details,

            generating_revenue: $scope.pitchDetails.generating_revenue,
            average_turnover: $scope.pitchDetails.average_turnover,
            operating_profit: $scope.pitchDetails.operating_profit,
            historical_financials: $scope.pitchDetails.historical_financials,
            fiancials_history: $scope.pitchDetails.fiancials_history,
            current_runway: $scope.pitchDetails.current_runway,

            how_do_you_make_money: $scope.pitchDetails.how_do_you_make_money,
            evaluation_model: $scope.pitchDetails.evaluation_model,
            estimated_business_value: $scope.pitchDetails.estimated_business_value,
            willing_to_undergo_evaluation: $scope.pitchDetails.willing_to_undergo_evaluation,
            received_prior_funding: $scope.pitchDetails.received_prior_funding,
            how_much_funding: $scope.pitchDetails.how_much_funding,
            do_you_have_lead_investors: $scope.pitchDetails.do_you_have_lead_investors,

            expecting_more_funding: $scope.pitchDetails.expecting_more_funding,
            more_funding_details: $scope.pitchDetails.more_funding_details,
            primary_need_for_capital: $scope.pitchDetails.primary_need_for_capital,
            how_will_you_invest: $scope.pitchDetails.how_will_you_invest,
            runway_for_investment: $scope.pitchDetails.runway_for_investment,

            potential_return: $scope.pitchDetails.potential_return,
            time_for_potential_return: $scope.pitchDetails.time_for_potential_return,
            return_on_investment: $scope.pitchDetails.return_on_investment,

            potential_risks: $scope.pitchDetails.potential_risks,

            how_is_equity_split: $scope.pitchDetails.how_is_equity_split,
            existing_shareholding_agreements: $scope.pitchDetails.existing_shareholding_agreements,
            existing_shareholder_agreement_with: $scope.pitchDetails.existing_shareholder_agreement_with,
            employment_share_pools: $scope.pitchDetails.employment_share_pools,
            existing_holdings_structure: $scope.pitchDetails.existing_holdings_structure,
            existing_holdings: $scope.pitchDetails.existing_holdings,

            submission: submission
        };

        PitchSetupService.editPitch(pitch_id, details).then(function () {
            $state.transitionTo('create-pitch.thank-you');
        });
    };

    $scope.viewProfile = function () {
        UserService.getUserDetails().then(function (res) {
            $state.transitionTo('user-profile', { user_id: res.user_id });
        });
    };

    $scope.deleteDocument = function ($event, index) {
        $event.preventDefault();
        if (!$scope.documents) {
            return;
        }
        $scope.documents.splice(index, 1);
    };
});
'use strict';

angular.module('uprise').factory('PitchService', function ($http) {

    var service = {

        retrieveFeaturedPitches: function retrieveFeaturedPitches() {
            var req = {
                method: 'GET',
                url: '/api/pitch/featured_list',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000
            };

            return $http(req);
        },

        retrievePitchInvestmentOptions: function retrievePitchInvestmentOptions(pitchId) {

            var req = {
                method: 'GET',
                url: '/api/pitch/investment_options/' + pitchId,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000
            };

            return $http(req);
        },

        fetchPitchCategories: function fetchPitchCategories() {
            return $http.get('/api/pitch/fetch_categories');
        },

        retrievePitchInvestorsCount: function retrievePitchInvestorsCount(pitchId) {

            var req = {
                method: 'GET',
                url: '/api/pitch/fetch_investors/' + pitchId,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000
            };

            return $http(req);
        }

    };

    return service;
});
'use strict';

angular.module('uprise').factory('PitchSetupService', function ($http, $q) {

    var service = {

        createPitch: function createPitch() {
            var req = {
                method: 'POST',
                url: '/api/pitch_crud/',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000
            };

            return $http(req);
        },

        editPitch: function editPitch(pitchId, details) {
            var req = {
                method: 'PUT',
                url: '/api/pitch_crud/' + pitchId,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000,
                data: {
                    details: details
                }
            };

            return $http(req);
        },

        fetchPitchCreationDetails: function fetchPitchCreationDetails(pitch_id) {
            return $http.get('/api/pitch/pitch_creation/' + pitch_id);
        },

        fetchPitchDetails: function fetchPitchDetails(pitch_id) {
            return $http.get('/api/pitch_crud/' + pitch_id);
        },

        fetchPitchDocuments: function fetchPitchDocuments(pitch_id) {
            return $http.get('/api/pitch/fetch_documents/' + pitch_id);
        },

        deletePitch: function deletePitch(pitch_id) {
            return $http.get('/api/pitch/delete/' + pitch_id);
        }

    };

    return service;
});
'use strict';

angular.module('uprise').controller('UserProfileController', function ($scope, $timeout, $location, UserService, PitchSetupService, $state) {

    $scope.editProfile = false;
    $scope.userProfileLoaded = false;
    $scope.pitchToDelete = {};
    $scope.change_password_submitted = false;
    $scope.loading_page = true;
    $scope.email_verification_sent = false;

    var user_id = $state.params.user_id;

    var init = function init() {
        $('.modal').modal();

        UserService.fetchUserProfile(user_id).then(function (res) {
            $scope.userPitches = res.data.pitches;
            $scope.userProfileDetails = res.data.user_info;
            $scope.userProfileDetails.investor_type_clean = '';

            // if (!$scope.userProfileDetails.investor_type) {
            //     $state.transitionTo('investor_type');
            //     return
            // }
            if ($scope.userProfileDetails.investor_type) {
                $scope.userProfileDetails.investor_type_clean = $scope.userProfileDetails.investor_type.toLowerCase().replace('_', ' ');
                $scope.userProfileDetails.investor_type_clean = $scope.userProfileDetails.investor_type_clean.substring(0, 1).toUpperCase() + $scope.userProfileDetails.investor_type_clean.substring(1);
                $scope.userInvestments = res.data.investments;
            }

            $scope.loading_page = false;
        });
    };

    init();

    $scope.deletePitchModal = function (pitch) {
        $scope.pitchToDelete = pitch;
        $('#deletePitch').modal('open');
    };

    $scope.deletePitch = function () {
        $('#deletePitch').modal('close');
        PitchSetupService.deletePitch($scope.pitchToDelete.id).then(function () {
            init();
        });
    };

    $scope.createPitch = function () {
        PitchSetupService.createPitch().then(function (res) {
            $state.transitionTo('create-pitch.details', { pitch_id: res.data.id });
        });
    };

    $scope.saveProfile = function () {
        UserService.editProfile($scope.userProfileDetails.email_address, $scope.userProfileDetails.investor_type).then(function (res) {
            $timeout(function () {
                $scope.editProfile = false;
                $scope.userProfileDetails = res.data.user;
                $scope.userProfileDetails.investor_type_clean = $scope.userProfileDetails.investor_type.toLowerCase().replace('_', ' ');
                $scope.userProfileDetails.investor_type_clean = $scope.userProfileDetails.investor_type_clean.substring(0, 1).toUpperCase() + $scope.userProfileDetails.investor_type_clean.substring(1);
            }, 0);
        });
    };

    $scope.verifyUserProfile = function () {
        UserService.verifyUserProfile($location.path()).then(function () {
            $timeout(function () {
                $scope.email_verification_sent = true;
            }, 0);
        });
    };

    $scope.resumePitchFunding = function (pitch_id) {
        if (!$scope.userProfileDetails.investment_knowledge_assessment) {
            $state.transitionTo('pitch-funding.assessment', { pitch_id: pitch_id });
        }

        if ($scope.userProfileDetails.investment_knowledge_assessment && !$scope.userProfileDetails.proof_of_address_url && !$scope.userProfileDetails.id_document_url && !$scope.userProfileDetails.suburb) {
            $state.transitionTo('pitch-funding.your_details', { pitch_id: pitch_id });
        }

        if ($scope.userProfileDetails.proof_of_address_url && $scope.userProfileDetails.id_document_url && $scope.userProfileDetails.suburb) {
            $state.transitionTo('pitch-funding.disclaimer', { pitch_id: pitch_id });
        }

        if ($scope.userProfileDetails.proof_of_address_url && $scope.userProfileDetails.suburb) {
            $state.transitionTo('pitch-funding.disclaimer', { pitch_id: pitch_id });
        }
    };

    $scope.navigateToDocument = function (pitch_id) {
        $state.transitionTo('pitch-funding-pledge-agreement', { pitch_id: pitch_id });
    };

    $scope.changePassword = function () {
        $scope.change_password_submitted = true;
        UserService.changePassword($scope.userProfileDetails.email_address);
    };
});
'use strict';

angular.module('uprise').controller('SignInUserController', function ($scope, $rootScope, $timeout, $state, UserService) {

    $scope.email_address = "";
    $scope.no_user_found = false;
    $scope.forgot_password = false;
    $scope.no_email_address = false;
    $scope.submitting_form = false;
    $scope.incorrect_details = false;
    $scope.redirect_user_after_login = false;

    if ($state.params.previousState) {
        $scope.redirect_user_after_login = true;
    }

    $scope.signIn = function ($event) {

        var payload = {
            email_address: $scope.email_address,
            password: $event.currentTarget.elements['password'].value // so we don't store the password on the scope

        };

        $scope.submitting_form = true;

        UserService.userSignin(payload).then(function (response) {
            $rootScope.$broadcast('handleLoginStateChangeBroadcast', response.data);

            if (localStorage.getItem('tmtData')) {
                UserService.saveAdmarulaToken(localStorage.getItem('tmtData')).then(function () {
                    localStorage.removeItem('tmtData');
                });
            }

            var nextState = "home";
            if ($state.params && $state.params.previousState) {
                nextState = $state.params.previousState.name;
            }

            if ($state.params && $state.params.previousState && $state.params.previousState.param_name && !!$state.params.previousState.param_id) {

                var _payload = {};
                _payload[$state.params.previousState.param_name] = $state.params.previousState.param_id;
                $state.transitionTo(nextState, _payload);
            } else {

                $state.transitionTo(nextState);
            }

            $scope.submitting_form = false;
        }, function (err) {
            console.log(err);
            if (err.status === 401) {
                $scope.incorrect_details = true;
                $timeout(function () {
                    $scope.incorrect_details = false;
                }, 6000);
            }
            $scope.submitting_form = false;
        });
    };

    $scope.signInWithFacebook = function () {
        $scope.submitting_form = true;

        FB.login(function (response) {
            var accessToken = response.authResponse.accessToken;
            if (response.status === 'connected') {
                FB.api('/me', function () {
                    UserService.facebookUserSignIn(accessToken).then(function (res) {
                        $rootScope.$broadcast('handleLoginStateChangeBroadcast', res.data);
                        UserService.updateUserDetails(res.data);

                        if (localStorage.getItem('tmtData')) {
                            UserService.saveAdmarulaToken(localStorage.getItem('tmtData')).then(function () {
                                localStorage.removeItem('tmtData');
                            });
                        }

                        var nextState = "home";
                        if ($state.params && $state.params.previousState) {
                            nextState = $state.params.previousState.name;
                        }

                        if ($state.params && $state.params.previousState && $state.params.previousState.param_name && !!$state.params.previousState.param_id) {

                            var payload = {};
                            payload[$state.params.previousState.param_name] = $state.params.previousState.param_id;
                            $state.transitionTo(nextState, payload);
                        } else {

                            $state.transitionTo(nextState);
                        }

                        $scope.submitting_form = false;
                    }).catch(function () {
                        $scope.no_user_found = true;
                        $scope.submitting_form = false;
                    });
                });
            } else {
                console.log('[ERROR] User cancelled login or did not fully authorise (Facebook).');
            }
        }, { scope: 'email', return_scopes: true });
    };

    $scope.forgotPassword = function () {
        if (!$scope.email_address) {
            $scope.no_email_address = true;
            return;
        }
        UserService.forgotPassword($scope.email_address).then(function () {
            $scope.forgot_password = true;
            $scope.no_email_address = false;
        });
    };

    // Set the default value of inputType
    $scope.inputType = "password";

    // Hide & show password function
    $scope.hideShowPassword = function () {
        if ($scope.inputType == "password") $scope.inputType = "text";else $scope.inputType = "password";
    };

    $scope.options = {
        'width': 240,
        'height': 50,
        'longtitle': true,
        'onsuccess': function onsuccess(response) {
            UserService.GoogleUserSignIn(response).then(function (res) {
                $rootScope.$broadcast('handleLoginStateChangeBroadcast', res.data);

                UserService.updateUserDetails(res.data);

                if (localStorage.getItem('tmtData')) {
                    UserService.saveAdmarulaToken(localStorage.getItem('tmtData')).then(function () {
                        localStorage.removeItem('tmtData');
                    });
                }

                var nextState = "home";
                if ($state.params && $state.params.previousState) {
                    nextState = $state.params.previousState.name;
                }

                if ($state.params && $state.params.previousState && $state.params.previousState.param_name && !!$state.params.previousState.param_id) {

                    var payload = {};
                    payload[$state.params.previousState.param_name] = $state.params.previousState.param_id;
                    $state.transitionTo(nextState, payload);
                } else {

                    $state.transitionTo(nextState);
                }

                $scope.submitting_form = false;
            }).catch(function () {
                $scope.no_user_found = true;
                $scope.submitting_form = false;
            });
        }
    };
});
'use strict';

angular.module('uprise').controller('SignUpUserController', function ($scope, $window, $rootScope, UserService, $state) {

    $scope.first_name = "";
    $scope.last_name = "";
    $scope.email_address = "";
    $scope.user_exists = false;
    $scope.submitting_form = false;

    var dataLayer = $window.dataLayer = $window.dataLayer || [];

    $scope.signUp = function ($event) {

        $scope.submitting_form = true;

        var payload = {
            first_name: $scope.first_name,
            last_name: $scope.last_name,
            email_address: $scope.email_address,
            password: $event.currentTarget.elements['password'].value // so we don't store the password on the scope
        };

        UserService.userSignup(payload).then(function (response) {

            dataLayer.push({
                'event': 'userRegistered',
                'userId': response.data.user_id
            });

            $rootScope.$broadcast('handleLoginStateChangeBroadcast', response.data);
            if (localStorage.getItem('tmtData')) {
                UserService.saveAdmarulaToken(localStorage.getItem('tmtData')).then(function () {
                    localStorage.removeItem('tmtData');
                });
            }
            $state.transitionTo("investor_type");
            $scope.submitting_form = false;
        }, function (response) {
            console.log(response);
        });
    };

    $scope.joinWithFacebook = function () {
        $scope.submitting_form = true;
        FB.login(function (res) {
            var accessToken = res.authResponse.accessToken;
            if (res.status === 'connected') {
                FB.api('/me', { fields: 'first_name, last_name, email' }, function (response) {
                    UserService.facebookUserSignUp(response, accessToken).then(function (res) {
                        $rootScope.$broadcast('handleLoginStateChangeBroadcast', res.data);
                        UserService.updateUserDetails(res.data);

                        dataLayer.push({
                            'event': 'userRegistered',
                            'userId': res.data.user_id
                        });

                        if (localStorage.getItem('tmtData')) {
                            UserService.saveAdmarulaToken(localStorage.getItem('tmtData')).then(function () {
                                localStorage.removeItem('tmtData');
                            });
                        }
                        $state.transitionTo("investor_type");
                        $scope.submitting_form = false;
                    }).catch(function () {
                        $scope.user_exists = true;
                        $scope.submitting_form = false;
                    });
                });
            } else {
                console.log('[ERROR] User cancelled login or did not fully authorise (Facebook).');
            }
        }, { scope: 'email', return_scopes: true });
    };

    // Set the default value of inputType
    $scope.inputType = "password";

    // Hide & show password function
    $scope.hideShowPassword = function () {
        if ($scope.inputType == "password") $scope.inputType = "text";else $scope.inputType = "password";
    };

    $scope.options = {
        'width': 240,
        'height': 50,
        'longtitle': true,
        'onsuccess': function onsuccess(response) {
            UserService.GoogleUserSignUp(response).then(function (res) {

                dataLayer.push({
                    'event': 'userRegistered',
                    'userId': res.data.user_id
                });

                $rootScope.$broadcast('handleLoginStateChangeBroadcast', res.data);
                UserService.updateUserDetails(res.data);
                if (localStorage.getItem('tmtData')) {
                    UserService.saveAdmarulaToken(localStorage.getItem('tmtData')).then(function () {
                        localStorage.removeItem('tmtData');
                    });
                }
                $state.transitionTo("investor_type");
                $scope.submitting_form = false;
            }).catch(function () {
                $scope.user_exists = true;
                $scope.submitting_form = false;
            });
        }
    };
});
'use strict';

angular.module('uprise').controller('EmailVerificationController', function ($scope, $window, $timeout, UserService, $state) {

    $scope.investorType = "";

    var user_id = $state.params.user_id;
    var token = $state.params.token;

    var init = function init() {
        UserService.verifyToken(user_id, token).then(function () {
            UserService.redirectUser(user_id).then(function (res) {
                var redirect_url = res.data.redirect_url;
                $timeout(function () {
                    $window.location.href = redirect_url;
                }, 3000);
            });
        });
    };

    init();
});
'use strict';

angular.module('uprise').controller('UserInvestorTypeController', function ($scope, UserService, $state) {

        $scope.investorType = "";
        $scope.selectedUserType = "";

        $scope.navigateToStart = function () {
                $state.transitionTo("welcome");
        };

        $scope.setUserInvestorType = function () {

                UserService.setUserType($scope.selectedUserType).then(function () {
                        $state.transitionTo("welcome");
                }, function (err) {
                        console.log(err);
                });
        };
});
'use strict';

angular.module('uprise').controller('UserRaiseCapitalController', function ($scope, $state, UserService, PitchSetupService) {

    var init = function init() {
        UserService.getUserDetails().then(function () {
            PitchSetupService.createPitch().then(function (res) {
                $state.transitionTo('create-pitch.details', { pitch_id: res.data.id });
            });
        }, function (err) {
            $state.transitionTo('signin');
        });
    };

    init();
});
'use strict';

angular.module('uprise').controller('UserWelcomeController', function ($scope, $state, UserService, PitchSetupService) {

    var init = function init() {
        UserService.FetchUserDetails().then(function (res) {
            $scope.user = res.data.user_info;
        });
    };

    init();

    $scope.createPitch = function () {
        PitchSetupService.createPitch().then(function (res) {
            $state.transitionTo('create-pitch.details', { pitch_id: res.data.id });
        });
    };
});
'use strict';

angular.module('uprise').directive('googleSignInButton', function () {
    return {
        scope: {
            buttonId: '@',
            options: '&'
        },
        template: '<div></div>',
        link: function link(scope, element, attrs) {
            var div = element.find('div')[0];
            div.id = attrs.buttonId;
            gapi.signin2.render(div.id, scope.options()); //render a google button, first argument is an id, second options
        }
    };
});
'use strict';

angular.module('uprise').factory('LoginLogoutBroadcast', ['$rootScope', function ($rootScope) {

        var sharedService = {};

        sharedService.loginStateChanged = function () {
                this.broadcastLoginStateChanged();
        };

        sharedService.broadcastLoginStateChanged = function () {
                $rootScope.$broadcast('handleLoginStateChangeBroadcast');
        };

        return sharedService;
}]);
'use strict';

angular.module('uprise').factory('UserService', function ($http, $q) {

    var userDetails = null;

    function setUserDetails(userObject) {
        userDetails = userObject;
    }

    var service = {

        userDetails: userDetails,

        isAuthenticated: function isAuthenticated() {

            if (!userDetails || typeof userDetails.is_authenticated === "undefined") return false;
            return userDetails.is_authenticated;
        },

        getUserDetails: function getUserDetails() {
            var deferred = $q.defer();
            if (userDetails != null) {
                deferred.resolve(userDetails);
                return deferred.promise;
            }

            $http.get('/api/user').then(function (response) {
                setUserDetails(response.data);
                deferred.resolve(userDetails);
            }, function (error) {
                console.log(error);
                deferred.reject(error);
            });

            return deferred.promise;
        },

        checkUserSignedIn: function checkUserSignedIn() {
            var deferred = $q.defer();

            this.getUserDetails().then(function (response) {

                if (response.is_authenticated) {
                    return deferred.resolve(response);
                }

                return deferred.resolve();
            }, function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        },
        updateUserDetails: function updateUserDetails(user) {
            setUserDetails(user);
        },
        userSignup: function userSignup(formData) {
            var req = {
                method: 'POST',
                url: '/api/user/register',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000,
                data: formData
            };

            return $http(req).then(function (response) {
                setUserDetails(response.data);
                return response;
            }, function errorHandler(response) {
                console.log(response);
            });
        },

        userSignin: function userSignin(formData) {
            var req = {
                method: 'POST',
                url: '/api/user/login',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000,
                data: formData
            };

            return $http(req).then(function (response) {
                setUserDetails(response.data);
                return response;
            }, function errorHandler(response) {
                return $q.reject(response);
            });
        },

        userSignout: function userSignout() {
            var req = {
                method: 'GET',
                url: '/api/user/logout',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000
            };

            return $http(req).then(function (response) {
                setUserDetails(null);
                return response;
            }, function errorHandler(response) {
                setUserDetails(null);
                console.log(response);
            });
        },

        userForgotPassword: function userForgotPassword(formData) {
            var req = {
                method: 'POST',
                url: '/api/user/forgot_password',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000,
                data: formData

            };

            return $http(req).then(function (response) {
                setUserDetails(response.data);
                return response;
            }, function errorHandler(response) {
                console.log(response);
            });
        },

        userResetPassword: function userResetPassword(formData) {
            var req = {
                method: 'POST',
                url: '/api/user/reset_password',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 60000,
                data: formData

            };

            return $http(req).then(function (response) {
                setUserDetails(response.data);
                return response;
            }, function errorHandler(response) {
                console.log(response);
            });
        },

        setUserInvestorType: function setUserInvestorType(selectedUserType, investorType) {

            var req = {
                method: 'POST',
                url: '/api/user/set_investor_type',
                timeout: 60000,
                data: {
                    'investor_type': investorType,
                    'user_type': selectedUserType
                }
            };

            return $http(req);
        },

        setUserType: function setUserType(selectedUserType) {

            var req = {
                method: 'POST',
                url: '/api/user/set_user_type',
                timeout: 60000,
                data: {
                    'user_type': selectedUserType
                }
            };

            return $http(req);
        },

        setUserInvestmentExperience: function setUserInvestmentExperience(previousInvestmentExperience, previousInvestments, investorType) {

            var req = {
                method: 'POST',
                url: '/api/user/set_investment_experience',
                timeout: 60000,
                data: {
                    previous_investment_experience: previousInvestmentExperience,
                    previous_investments: previousInvestments,
                    investor_type: investorType
                }
            };

            return $http(req);
        },

        setUserKYCDetails: function setUserKYCDetails(investorDetails, pitchId) {

            var req = {
                method: 'POST',
                url: '/api/user/set_kyc_details',
                timeout: 60000,
                data: {
                    investor: investorDetails,
                    pitch_id: pitchId
                }
            };

            return $http(req);
        },

        fetchUserProfile: function fetchUserProfile(user_id) {
            var req = {
                method: 'POST',
                url: '/api/user/fetch_user_profile',
                timeout: 60000,
                data: {
                    user_id: user_id
                }
            };

            return $http(req);
        },

        editProfile: function editProfile(email_address, investor_type) {
            var req = {
                method: 'POST',
                url: '/api/user/edit',
                timeout: 60000,
                data: {
                    email_address: email_address,
                    investor_type: investor_type
                }
            };

            return $http(req);
        },

        verifyUserProfile: function verifyUserProfile(redirect_user_url) {
            var req = {
                method: 'POST',
                url: '/api/user/email_address_verification',
                timeout: 60000,
                data: {
                    redirect_user_url: redirect_user_url
                }
            };

            return $http(req);
        },

        changePassword: function changePassword(email_address) {
            var req = {
                method: 'POST',
                url: '/api/user/forgot_password',
                timeout: 60000,
                data: {
                    email_address: email_address
                }
            };

            return $http(req);
        },

        facebookUserSignUp: function facebookUserSignUp(facebook, access_token) {
            var req = {
                method: 'POST',
                url: '/api/user/facebook_user_signup',
                timeout: 60000,
                data: {
                    first_name: facebook.first_name,
                    last_name: facebook.last_name,
                    email_address: facebook.email,
                    access_token: access_token
                }
            };

            return $http(req);
        },

        facebookUserSignIn: function facebookUserSignIn(access_token) {
            var req = {
                method: 'POST',
                url: '/api/user/facebook_user_sign_in',
                timeout: 60000,
                data: {
                    access_token: access_token
                }
            };

            return $http(req);
        },

        GoogleUserSignUp: function GoogleUserSignUp(google) {
            var req = {
                method: 'POST',
                url: '/api/user/google_user_signup',
                data: {
                    google: google
                }
            };

            return $http(req);
        },

        GoogleUserSignIn: function GoogleUserSignIn(google) {
            var req = {
                method: 'POST',
                url: '/api/user/google_user_sign_in',
                data: {
                    google: google
                }
            };

            return $http(req);
        },

        newsletterSignUp: function newsletterSignUp(email_address) {
            var req = {
                method: 'POST',
                url: '/api/user/newsletter_signup',
                data: {
                    email_address: email_address
                }
            };

            return $http(req);
        },

        forgotPassword: function forgotPassword(email_address) {
            var req = {
                method: 'POST',
                url: '/api/user/forgot_password',
                data: {
                    email_address: email_address
                }
            };

            return $http(req);
        },

        fetchGoogleClientId: function fetchGoogleClientId() {
            return $http.get('/api/user/fetch_google_client_id');
        },

        FetchUserDetails: function FetchUserDetails() {
            return $http.get('/api/user/fetch_user_details');
        },

        saveAdmarulaToken: function saveAdmarulaToken(admarula_token) {
            var req = {
                method: 'POST',
                url: '/api/user/save_admarula_token',
                data: {
                    admarula_token: admarula_token
                }
            };

            return $http(req);
        },

        verifyToken: function verifyToken(user_id, token) {
            var req = {
                method: 'POST',
                url: '/api/user/verify_token',
                data: {
                    user_id: user_id,
                    token: token
                }
            };

            return $http(req);
        },

        redirectUser: function redirectUser(user_id) {
            var req = {
                method: 'POST',
                url: '/api/user/redirect_user_after_verification',
                data: {
                    user_id: user_id
                }
            };

            return $http(req);
        }

    };

    service.getUserDetails();

    return service;
});