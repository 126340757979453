angular.module('uprise')
    .controller('PitchFundingPaymentSuccessController', function ($scope, $state, UserService, PitchSetupService) {

        let init = function () {
            if ($state.params.pitch) {
                PitchSetupService.fetchPitchDetails($state.params.pitch).then(res => {
                    $scope.pitch = res.data;
                });
            }
        };

        init();

        $scope.viewProfile = function () {
            UserService.getUserDetails().then(res => {
                $state.transitionTo('user-profile', {user_id: res.user_id})
            });
        };
    });

