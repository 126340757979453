angular.module('uprise')
    .controller('NavController', function ($scope, $state, UserService, PitchSetupService) {

            $scope.isAuthenticated = false;
            $scope.state = $state;
            $scope.search = {};

            $scope.$on('handleLoginStateChangeBroadcast', function (event, args) {
                $scope.isAuthenticated = true;
                $scope.userDetails = args;
            });

            $scope.navigateToProfile = function () {
                $state.transitionTo('user-profile', {user_id: $scope.userDetails.user_id})
            };

            $scope.signOut = function () {
                UserService.userSignout().then(res => {
                    var auth2 = gapi.auth2.getAuthInstance();
                    auth2.signOut().then(function () {
                        auth2.disconnect();
                        $scope.isAuthenticated = false;
                        $scope.userDetails = null;
                        $state.transitionTo('signin');
                    });

                });
            };

            $scope.submitSearchQuery = function () {
                $state.transitionTo('discover', {query_string: $scope.search.query_string});
            };

            $scope.raiseCapital = function () {

                //is the user already signed in?
                UserService.checkUserSignedIn().then(res => {

                    if (res) {
                        //yes, signed in, retrieve their profile details
                        UserService.fetchUserProfile(res.user_id).then(res => {

                            //have they indicated their investor type?
                            if (res.data.user_info.investor_type) {

                                //yes, create an empty pitch and send them to setup page
                                PitchSetupService.createPitch().then(res => {
                                    $state.transitionTo('create-pitch.details', {pitch_id: res.data.id});
                                });


                            } else {

                                //request their investor type
                                $state.transitionTo('investor_type');

                            }

                        });
                    }

                }, reject => {

                    //not signed in, take them to signin
                    $state.transitionTo('signin', {previousState: {name: 'raise-capital'}});

                });

            };

        }
    );