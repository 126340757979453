angular.module('uprise')
    .controller('BaseController', function ($scope, UserService) {

        let init = function () {
            UserService.checkUserSignedIn()
        };

        $scope.$on('handleLoginStateChangeBroadcast', () => {
            UserService.checkUserSignedIn().then(res => {
                if (res) {
                    $scope.user_details = res;
                }
            });
        })

    });
