angular.module('uprise')

    .factory('LoginLogoutBroadcast', ['$rootScope', function ($rootScope) {

        var sharedService = {};

        sharedService.loginStateChanged = function () {
            this.broadcastLoginStateChanged();
        };

        sharedService.broadcastLoginStateChanged = function () {
            $rootScope.$broadcast('handleLoginStateChangeBroadcast');
        };

        return sharedService;


    }]);
