angular.module('uprise')
    .controller('DiscoverController', function ($scope, $state, $sce, DiscoverService) {

        DiscoverService.query_string = $state.params.query_string;

        $scope.pitches = {};
        $scope.loading_page = true;

        $scope.trustHtml = function (html) {
            return $sce.trustAsHtml(html);
        };

        let init = function () {
            DiscoverService.searchPitches().then(res => {
                $scope.loading_page = false;
                $scope.pitches = res.data.pitches;
                angular.forEach($scope.pitches, function (pitch) {
                    let end_date = moment(pitch.finish_date);
                    let todays_date = moment();
                    pitch.days_left = end_date.diff(todays_date, 'days');
                    if (pitch.short_blurb.length > 150) {
                        pitch.short_blurb = pitch.short_blurb.slice(0, 150);
                        pitch.short_blurb += ` <span>...read more</span>`;
                    }
                    pitch.short_blurb = $scope.trustHtml(pitch.short_blurb);
                })
            })
        };

        init();

        $scope.viewPitchDetails = function (pitchId) {
            $state.transitionTo('view-pitch-details', {pitch_id: pitchId});

        };

    });