angular.module('uprise')

    .controller('UserRaiseCapitalController', function ($scope, $state, UserService, PitchSetupService) {

        let init = function () {
            UserService.getUserDetails().then(() => {
                PitchSetupService.createPitch().then(res => {
                    $state.transitionTo('create-pitch.details', {pitch_id: res.data.id});
                });
            }, err => {
                $state.transitionTo('signin');
            })
        };

        init();

    });